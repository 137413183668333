import {makeAutoObservable} from "mobx"
import {isPossiblePhoneNumber} from "react-phone-number-input"
import axios from 'axios'
import {getFullDate} from "./utils"
import {banksList, playgroundsList, clientsList, archiveList, ratingsList} from "./components/constants"

const BASE_URL = 'http://87.117.3.96:3088/API_1/'
const resp = 'response'
const token = 'sessionToken'
const generalData = 'generalData'
const balance = 'balances'

class Store {
  constructor() {
    makeAutoObservable(this)
    this.auth.loadToken()
    this.inputs.initializeInputs('logIn', ['login', 'password', 'formAvailable'])
    this.inputs.initializeInputs('registration', ['name', 'companyName', 'fio', 'contactPerson', 'phone', 'email', 'password', 'formAvailable'])
    this.inputs.setInput('registration', 'userType', 'fiz')
    this.inputs.initializeInputs('recoveryPage', ['email', 'formAvailable'])
    this.inputs.initializeInputs('mainLayout', ['blocked'])
    this.inputs.setInput('location', 'page', 'Главная')
    this.data.setData('banksList', banksList)
    this.data.setData('playgroundsList', playgroundsList)
    this.data.setData('clientsList', clientsList)
    this.data.setData('archiveList', archiveList)
    this.data.setData('ratingsList', ratingsList)
    this.operatingWindow.setTab('adminBanks', '')
    this.operatingWindow.setTab('adminRatings', '')
    this.operatingWindow.setTab('adminClients', '')
  }

  auth = {
    token: '',
    setToken: (token: string) => {
      this.auth.token = token
      localStorage.setItem('_token', token)
    },
    clearToken: () => {
      this.auth.token = ''
      localStorage.setItem('_token', '')
    },
    loadToken: () => {
      let token = localStorage.getItem('_token')
      if (!token) {
        localStorage.setItem('_token', '')
      } else {
        this.auth.token = token
        return axios({
          method: 'post',
          url: `${BASE_URL}getCurrentUserData`,
          headers: {'sessionToken': this.auth.token},
        })
          .then((response) => {
            this.currentUser.updateGeneralData(response.data[resp][generalData])
            this.currentUser.updateBalance(response.data[resp][balance])
          })
          .catch(() => {
            console.log('При загрузке данных пользователь произошла ошибка')
          })
      }
    },
    loadVerificationToken: () => {},
    registerUser: (callback: any) => {
      let data = this.inputs.fieldsToSend.reduce((acc: {}, curr: string) => {
        if (this.inputs.fieldsToSend.includes(curr)) {
          acc[curr] = this.inputs.list.registration[curr].value
        }
        return acc
      }, {})
      return axios({
        method: 'post',
        url: `${BASE_URL}registration`,
        data,
      })
        .then((response) => {
          this.auth.setToken(response.data[resp][token])
          this.currentUser.updateGeneralData(response.data[resp][generalData])
          this.currentUser.updateBalance(response.data[resp][balance])
          callback()
        })
        .catch(() => {
          this.errors.setError('authPage', 'registration', 'Данный email уже зарегистрирован')
        })
    },
    authUser: () => {
      return axios({
        method: 'post',
        url: `${BASE_URL}logIn`,
        data: {
          login: this.inputs.list.logIn.login.value,
          password: this.inputs.list.logIn.password.value,
        },
      })
        .then((response) => {
          this.auth.setToken(response.data[resp][token])
          this.currentUser.updateGeneralData(response.data[resp][generalData])
          this.currentUser.updateBalance(response.data[resp][balance])
          this.inputs.setInput('logIn', 'login', '')
          this.inputs.setInput('logIn', 'password', '')
        })
        .catch(() => {
          this.errors.setError('authPage', 'login', 'Вы ввели неверный логин или пароль')
        })
    },
    recoveryUser: (callback: any) => {
      return axios({
        method: 'post',
        url: `${BASE_URL}recovery`,
        data: {
          email: this.inputs.list.recoveryPage.email.value,
        },
      })
        .then(() => {
          this.inputs.setInput('recoveryPage', 'email', '')
          callback()
        })
        .catch(() => {
          this.errors.setError('recoveryPage', 'email', 'Указанный email не зарегистрирован')
        })
    },
    verificationEmail: (token: string, target: string) => {
      return axios({
        method: 'post',
        url: `${BASE_URL}checkEmailVerificationToken`,
        headers: {'sessionToken': this.auth.token},
        data: {
          emailVerificationToken: token,
          target: target,
        },
      })
        .then((response) => {
          console.log('response', response)
        })
        .catch((error) => {
          console.log('verification error', error)
        })
    },
    logOut: () => {
      this.auth.clearToken()
      this.currentUser.clearUser()
    },
  }

  errors = {
    list: {} as any,
    setError: (direction: string, error: string, value: any) => {
      if (this.errors.list[direction] === undefined) this.errors.list[direction] = {}
      this.errors.list[direction][error] = value
    },
    getErrorByName: (direction: string, error: string) => {
      if (this.errors.list[direction] === undefined) return ''
      return this.errors.list[direction][error]
    },
  }

  currentUser = {
    generalData: {},
    balance: {},
    updateGeneralData: (data: any) => {
      this.currentUser.generalData = data
    },
    updateBalance: (data: any) => {
      this.currentUser.balance = data
    },
    clearUser: () => {
      this.currentUser.generalData = {}
      this.currentUser.balance = {}
    },
    getUserDataByDirection: (direction: string) => {
      return this.currentUser[direction]
    },
    getUserDataByName: (field: string, name: string) => {
      return this.currentUser[field][name]
    },
    changeGeneralUserData: (direction: string, list: string[], callback: any) => {
      const dataToChange = {}
      for (let item = 0; item < list.length; item++) {
        dataToChange[list[item]] = this.inputs.getInputByName(direction, list[item]).value
      }
      return axios({
        method: 'post',
        url: `${BASE_URL}changeGeneralUserData`,
        headers: {'sessionToken': this.auth.token},
        data: {
          userId: this.currentUser.getUserDataByName('generalData', 'userId'),
          update: dataToChange,
        },
      })
        .then((response: any) => {
          this.currentUser.updateGeneralData(response.data[resp][generalData])
          callback()
        })
        .catch(() => {
          this.errors.setError('settingsPage', 'password', 'Ошибка при попытке сменить пароль')
        })
    },
    get userType() {
      return this.getUserDataByName('generalData', 'userType')
    },
  }

  data = {
    list: {} as any,
    setData: (name: string, data: any) => {
      if (this.data.list[name] === undefined) this.data.list[name] = {}
      this.data.list[name] = data
    },
    addDataItem: (name: string, item: any) => {
      if (item !== undefined) {
        this.data.list[name].push(item)
      }
    },
    getCurrentItem: (list: string, direction: string, name: string) => {
      return this.data.getData(list).find((el: any) => el.id === this.inputs.getInputByName(direction, name).value)
    },
    deleteDataItem: (name: string, id: string) => {
      if (id !== undefined) {
        const index = this.data.list[name].findIndex((el: any) => el.id === id)
        this.data.list[name].splice(index, 1)
      }
    },
    updateDataItem: (name: string, id: string, data: any) => {
      if (id !== undefined) {
        const index = this.data.list[name].findIndex((el: any) => el.id === id)
        this.data.list[name].splice(index, 1, data)
      }
    },
    getData: (name: string) => {
      return this.data.list[name]
    },
    getFilteredData: (name: string, key: string, value: string) => {
      const currentArray = this.data.list[name]
      const result = []
      for (let i = 0; i < currentArray.length; i++) {
        const obj = currentArray[i]
        if (value && (obj[key].toLowerCase()).includes(value.toLowerCase())) result.push(obj);
      }
      return result
    },
  }

  operatingWindow = {
    list: {} as any,
    setTab: (name: string, value: string) => {
      if (this.operatingWindow.list[name] === undefined) this.operatingWindow.list[name] = {}
      this.operatingWindow.list[name] = value
    },
    getTab: (name: string) => {
      return this.operatingWindow.list[name]
    },
  }

  inputs = {
    list: {} as any,
    setInput: (direction: string, name: string, value: any) => {
      if (this.inputs.list[direction] === undefined) this.inputs.list[direction] = {}
      if (this.inputs.list[direction][name] === undefined) this.inputs.list[direction][name] = {}
      this.inputs.list[direction][name] = {
        value: value,
        message: '',
      }
    },
    get fieldsToSend() {
      const userType = this.getInputByName('registration', 'userType')
      if (userType.value === 'fiz') {
        return ['userType', 'name', 'phone', 'email', 'password']
      } else if (userType.value === 'ur') {
        return ['userType', 'companyName', 'contactPerson', 'phone', 'email', 'password']
      } else {
        return ['userType', 'fio', 'contactPerson', 'phone', 'email', 'password']
      }
    },
    validateInput: (direction: string, name: string, inputType: string, regExp: any) => {
      if (this.inputs.list[direction] === undefined) return
      let input = this.inputs.list[direction][name]
      if (input.value === '') {
        input.message = 'Строка обязательна для заполнения'
      } else {
        if (inputType === 'common' && input.value.trim() === '') {
          input.message = 'Строка состоит только из пробелов'
        }
        if (inputType === 'format' && !regExp.test(input.value)) {
          input.message = 'Неверный формат ввода'
        }
        if (inputType === 'password' && input.value.length < 6) {
          input.message = 'Пароль слишком короткий'
        }
        if (inputType === 'phone') {
          if (typeof (input.value) !== "string") {
            input.message = 'Строка обязательна для заполнения'
          } else if (!isPossiblePhoneNumber(input.value)) {
            input.message = 'Неверный формат номера'
          }
        }
      }
    },
    getInputByName: (direction: string, name: string) => {
      if (this.inputs.list[direction] === undefined) return {}
      if (this.inputs.list[direction][name] === undefined) {
        return {}
      } else return this.inputs.list[direction][name]
    },
    getInputByDirection: (direction: string) => {
      return this.inputs.list[direction]
    },
    initializeInputs: (direction: string, list: string[]) => {
      const result = {}
      for (let item = 0; item < list.length; item++) {
        if (list[item] === 'formAvailable') {
          result[list[item]] = {value: false}
        } else if (list[item].includes('Date') || list[item].includes('At')) {
          result[list[item]] = {value: new Date()}
        } else if (list[item].length > 0) {
          result[list[item]] = {value: '', message: ''}
        }
      }
      this.inputs.list[direction] = result
    },
    setFormAvailable: (direction: string, list: string[]): void => {
      const condition = (item: any) => {
        if (typeof(this.inputs.list[direction][item].value) === "object") {
          return getFullDate(this.inputs.list[direction][item].value) !== getFullDate(new Date())
        } else {
          return this.inputs.list[direction][item].value !== '' &&
            (this.inputs.list[direction][item].message === '' || this.inputs.list[direction][item].message === undefined)
        }
      }
      this.inputs.list[direction].formAvailable.value = list.every(condition)
    },
    loadInput: (direction: string, name: string, data: any) => {
      const selected = ['value']
      this.inputs.list[direction][name] = Object.keys(data).reduce((acc: {}, curr: string) => {
        if (selected.includes(curr) && data[curr]) {
          acc[curr] = data[curr]
        }
        return acc
      }, {})
    },
    clear: () => {
      this.inputs.list = {}
    },
    initializeSettings: () => {
      this.inputs.initializeInputs('editCommon', ['name', 'companyName', 'fio', 'contactPerson', 'formAvailable'])
      this.inputs.initializeInputs('editESign', ['eSignType', 'whoHasGiven', 'owner', 'serialNumber', 'startDate', 'endDate', 'formAvailable'])
      this.inputs.initializeInputs('editEDetails', ['city', 'index', 'address', 'OGRNIP', 'KPP', 'INN', 'checkingAccount', 'bank', 'BIK', 'openDate', 'bankAccountNumber', 'bankOGRN', 'bankINN', 'bankKPP', 'openDate', 'formAvailable'])
    },
  }
}

export let STORE = new Store()
