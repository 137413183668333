import './footer.scss';
import '../MainLayout/main-layout.scss'

function Footer(): JSX.Element {
  return (
    <section className="footer">
      <div className="broad-container">
        <form action="/AnyDesk_STK.exe" className="anydesk">
          <p>Для удалённой работы с нами:</p>
          <button type="submit">Скачать AnyDesk
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2" y="12" width="10" height="10" transform="rotate(-45 2 12)" fill="white" />
              <rect width="9.91983" height="1.98397" transform="matrix(0.727722 0.685873 -0.727722 0.685873 14.4521 4.92871)" fill="white" />
              <rect width="10.3124" height="1.98397" transform="matrix(0.727722 -0.685873 0.727722 0.685873 13.0078 17.71)" fill="white" />
            </svg>
          </button>
        </form>
      </div>
    </section>
  )
}

export default Footer;
