import {banksType, playgroundsType, clientType, ratingsType} from "../types"

const CONVERT_SECONDS_TO_MONTHS = 2530016
const DEFAULT_RANGE = 0.03414998166019503
const DAYS_IN_MONTH = 30.417
const YEAR = 365

const banksList: banksType[] = [
	{id: '1', name: 'Акционерное Общество "Тинькофф-Банк', logo: 'bank-logos/TinkoffBank.png', alt: 'Tinkoff'},
	{id: '2', name: 'Публичное Акционерное Общество "Сбербанк"', logo: 'bank-logos/Sber.png', alt: 'Sber'},
	{id: '3', name: 'Акционерное Общество "Тинькофф-Банк', logo: 'bank-logos/TinkoffBank.png', alt: 'Tinkoff'},
	{id: '4', name: 'Публичное Акционерное Общество "Сбербанк"', logo: 'bank-logos/Sber.png', alt: 'Sber'},
]

const playgroundsList: playgroundsType[] = [
	{id: '1', abb: 'ЭТП ГПБ', name: 'Электронная торговая площадка ГазпромБанка'},
	{id: '2', abb: 'РосЭлТорг', name: 'Единая Электронная торговая площадка РосЭлТорг'},
	{id: '3', abb: 'АО "ЕЭТП', name: 'Ассоциация Электронных торговых площадок'},
	{id: '4', abb: 'РосЭлТорг', name: 'Единая Электронная торговая площадка РосЭлТорг'},
]

const clientsList : clientType[] = [
	{
		id: '1',
		createdAt: '2021-10-19T13:23:24.000Z',
		title: 'ООО "ДонСтройГрупп"',
		contract: '12345678-АП',
		signedAt: '2021-10-19T13:23:24.000Z',
		note: 'Особый клиент',
		userType: 'ur',
		bossFullName: 'Петров Иван Иванович',
		bossPhone: '+79285666366',
		bossEmail: 'DonStroyGrup@gmail.com',
		contactPerson: 'Иванов Александр Иванович',
		contactPhone: '+79285666366',
		contactEmail: 'DonStroyGrup@gmail.com',
		locationCity: 'Ростов--на-Дону',
		locationPostcode: '325659',
		locationAddress: 'Малиновского, 142',
		registrationCity: 'Ростов--на-Дону',
		registrationPostcode: '325659',
		registrationAddress: 'Малиновского, 142',
		organizationOGRN: '12345678910111213',
		organizationKPP: '12345678910111213',
		organizationAccountsList: [
			{
				organizationAccountId: '1',
				organizationAccountNumber: '999999999999999',
				organizationAccountSpecial: true,
			},
			{
				organizationAccountId: '2',
				organizationAccountNumber: '12345678910111213',
				organizationAccountSpecial: false,
			},
		],
		bankName: 'ПАО "Сбербанк"',
		bankBIK: '12345678910111213',
		bankCreatedAt: '2021-10-19T13:23:24.000Z',
		bankAccountNumber: '12345678910111213',
		bankOGRN: '12345678910111213',
		bankINN: '12345678910111213',
		bankKPP: '12345678910111213',
	},
	{
		id: '2',
		createdAt: '2021-10-19T13:23:24.000Z',
		title: 'ООО "РостовГрупп"',
		contract: '987654321-ПА',
		signedAt: '2021-10-19T13:23:24.000Z',
		note: 'Надежный клиент',
		userType: 'fiz',
		bossFullName: 'Петров Иван Иванович',
		bossPhone: '+79285666366',
		bossEmail: 'DonStroyGrup@gmail.com',
		contactPerson: 'Иванов Александр Иванович',
		contactPhone: '+79285666366',
		contactEmail: 'DonStroyGrup@gmail.com',
		locationCity: 'Ростов--на-Дону',
		locationPostcode: '325659',
		locationAddress: 'Малиновского, 142',
		registrationCity: 'Ростов--на-Дону',
		registrationPostcode: '325659',
		registrationAddress: 'Малиновского, 142',
		organizationOGRN: '12345678910111213',
		organizationKPP: '12345678910111213',
		organizationAccountsList: [
			{
				organizationAccountId: '1',
				organizationAccountNumber: '12345678910111213',
				organizationAccountSpecial: true,
			},
		],
		bankName: 'ПАО "Сбербанк"',
		bankBIK: '12345678910111213',
		bankCreatedAt: '2021-10-19T13:23:24.000Z',
		bankAccountNumber: '12345678910111213',
		bankOGRN: '12345678910111213',
		bankINN: '12345678910111213',
		bankKPP: '12345678910111213',
	},
	{
		id: '3',
		createdAt: '2021-10-19T13:23:24.000Z',
		title: 'ООО "МеталлИнвест"',
		contract: '98733311-ПП',
		signedAt: '2021-10-19T13:23:24.000Z',
		note: 'Скидка 25%',
		userType: 'ip',
		bossFullName: 'Петров Иван Иванович',
		bossPhone: '+79285666366',
		bossEmail: 'DonStroyGrup@gmail.com',
		contactPerson: 'Иванов Александр Иванович',
		contactPhone: '+79285666366',
		contactEmail: 'DonStroyGrup@gmail.com',
		locationCity: 'Ростов--на-Дону',
		locationPostcode: '325659',
		locationAddress: 'Малиновского, 142',
		registrationCity: 'Ростов--на-Дону',
		registrationPostcode: '325659',
		registrationAddress: 'Малиновского, 142',
		organizationOGRN: '12345678910111213',
		organizationKPP: '12345678910111213',
		organizationAccountsList: [
			{
				organizationAccountId: '1',
				organizationAccountNumber: '12345678910111213',
				organizationAccountSpecial: false,
			},
		],
		bankName: 'ПАО "Сбербанк"',
		bankBIK: '12345678910111213',
		bankCreatedAt: '2021-10-19T13:23:24.000Z',
		bankAccountNumber: '12345678910111213',
		bankOGRN: '12345678910111213',
		bankINN: '12345678910111213',
		bankKPP: '12345678910111213',
	},
	{
		id: '4',
		createdAt: '2021-10-19T13:23:24.000Z',
		title: 'ООО "Синергия"',
		contract: '9873221-КАП',
		signedAt: '2021-10-19T13:23:24.000Z',
		note: '',
		userType: 'ip',
		bossFullName: 'Петров Иван Иванович',
		bossPhone: '+79285666366',
		bossEmail: 'DonStroyGrup@gmail.com',
		contactPerson: 'Иванов Александр Иванович',
		contactPhone: '+79285666366',
		contactEmail: 'DonStroyGrup@gmail.com',
		locationCity: 'Ростов--на-Дону',
		locationPostcode: '325659',
		locationAddress: 'Малиновского, 142',
		registrationCity: 'Ростов--на-Дону',
		registrationPostcode: '325659',
		registrationAddress: 'Малиновского, 142',
		organizationOGRN: '12345678910111213',
		organizationKPP: '12345678910111213',
		organizationAccountsList: [
			{
				organizationAccountId: '1',
				organizationAccountNumber: '12345678910111213',
				organizationAccountSpecial: false,
			},
		],
		bankName: 'ПАО "Сбербанк"',
		bankBIK: '12345678910111213',
		bankCreatedAt: '2021-10-19T13:23:24.000Z',
		bankAccountNumber: '12345678910111213',
		bankOGRN: '12345678910111213',
		bankINN: '12345678910111213',
		bankKPP: '12345678910111213',
	},
	{
		id: '5',
		createdAt: '2021-10-19T13:23:24.000Z',
		title: 'ООО "Сингапур"',
		contract: '4444441-КОП',
		signedAt: '2021-10-19T13:23:24.000Z',
		note: 'Особый клиент',
		userType: 'fiz',
		bossFullName: 'Петров Иван Иванович',
		bossPhone: '+79285666366',
		bossEmail: 'DonStroyGrup@gmail.com',
		contactPerson: 'Иванов Александр Иванович',
		contactPhone: '+79285666366',
		contactEmail: 'DonStroyGrup@gmail.com',
		locationCity: 'Ростов--на-Дону',
		locationPostcode: '325659',
		locationAddress: 'Малиновского, 142',
		registrationCity: 'Ростов--на-Дону',
		registrationPostcode: '325659',
		registrationAddress: 'Малиновского, 142',
		organizationOGRN: '12345678910111213',
		organizationKPP: '12345678910111213',
		organizationAccountsList: [
			{
				organizationAccountId: '1',
				organizationAccountNumber: '12345678910111213',
				organizationAccountSpecial: false,
			},
		],
		bankName: 'ПАО "Сбербанк"',
		bankBIK: '12345678910111213',
		bankCreatedAt: '2021-10-19T13:23:24.000Z',
		bankAccountNumber: '12345678910111213',
		bankOGRN: '12345678910111213',
		bankINN: '12345678910111213',
		bankKPP: '12345678910111213',
	},
	{
		id: '6',
		createdAt: '2021-10-19T13:23:24.000Z',
		title: 'ООО "ЗомбиЛэнд"',
		contract: '2223341-АА',
		signedAt: '2021-10-19T13:23:24.000Z',
		note: 'Скидка 30%',
		userType: 'ur',
		bossFullName: 'Петров Иван Иванович',
		bossPhone: '+79285666366',
		bossEmail: 'DonStroyGrup@gmail.com',
		contactPerson: 'Иванов Александр Иванович',
		contactPhone: '+79285666366',
		contactEmail: 'DonStroyGrup@gmail.com',
		locationCity: 'Ростов--на-Дону',
		locationPostcode: '325659',
		locationAddress: 'Малиновского, 142',
		registrationCity: 'Ростов--на-Дону',
		registrationPostcode: '325659',
		registrationAddress: 'Малиновского, 142',
		organizationOGRN: '12345678910111213',
		organizationKPP: '12345678910111213',
		organizationAccountsList: [
			{
				organizationAccountId: '1',
				organizationAccountNumber: '12345678910111213',
				organizationAccountSpecial: false,
			},
		],
		bankName: 'ПАО "Сбербанк"',
		bankBIK: '12345678910111213',
		bankCreatedAt: '2021-10-19T13:23:24.000Z',
		bankAccountNumber: '12345678910111213',
		bankOGRN: '12345678910111213',
		bankINN: '12345678910111213',
		bankKPP: '12345678910111213',
	},
	{
		id: '7',
		createdAt: '2021-10-19T13:23:24.000Z',
		title: 'ООО "ЗомбиЛэнд"',
		contract: '2223341-АА',
		signedAt: '2021-10-19T13:23:24.000Z',
		note: 'Скидка 30%',
		userType: 'ur',
		bossFullName: 'Петров Иван Иванович',
		bossPhone: '+79285666366',
		bossEmail: 'DonStroyGrup@gmail.com',
		contactPerson: 'Иванов Александр Иванович',
		contactPhone: '+79285666366',
		contactEmail: 'DonStroyGrup@gmail.com',
		locationCity: 'Ростов--на-Дону',
		locationPostcode: '325659',
		locationAddress: 'Малиновского, 142',
		registrationCity: 'Ростов--на-Дону',
		registrationPostcode: '325659',
		registrationAddress: 'Малиновского, 142',
		organizationOGRN: '12345678910111213',
		organizationKPP: '12345678910111213',
		organizationAccountsList: [
			{
				organizationAccountId: '1',
				organizationAccountNumber: '12345678910111213',
				organizationAccountSpecial: false,
			},
		],
		bankName: 'ПАО "Сбербанк"',
		bankBIK: '12345678910111213',
		bankCreatedAt: '2021-10-19T13:23:24.000Z',
		bankAccountNumber: '12345678910111213',
		bankOGRN: '12345678910111213',
		bankINN: '12345678910111213',
		bankKPP: '12345678910111213',
	},
	{
		id: '8',
		createdAt: '2021-10-19T13:23:24.000Z',
		title: 'ООО "ЗомбиЛэнд"',
		contract: '2223341-АА',
		signedAt: '2021-10-19T13:23:24.000Z',
		note: 'Скидка 30%',
		userType: 'ur',
		bossFullName: 'Петров Иван Иванович',
		bossPhone: '+79285666366',
		bossEmail: 'DonStroyGrup@gmail.com',
		contactPerson: 'Иванов Александр Иванович',
		contactPhone: '+79285666366',
		contactEmail: 'DonStroyGrup@gmail.com',
		locationCity: 'Ростов--на-Дону',
		locationPostcode: '325659',
		locationAddress: 'Малиновского, 142',
		registrationCity: 'Ростов--на-Дону',
		registrationPostcode: '325659',
		registrationAddress: 'Малиновского, 142',
		organizationOGRN: '12345678910111213',
		organizationKPP: '12345678910111213',
		organizationAccountsList: [
			{
				organizationAccountId: '1',
				organizationAccountNumber: '12345678910111213',
				organizationAccountSpecial: false,
			},
		],
		bankName: 'ПАО "Сбербанк"',
		bankBIK: '12345678910111213',
		bankCreatedAt: '2021-10-19T13:23:24.000Z',
		bankAccountNumber: '12345678910111213',
		bankOGRN: '12345678910111213',
		bankINN: '12345678910111213',
		bankKPP: '12345678910111213',
	},
	{
		id: '9',
		createdAt: '2021-10-19T13:23:24.000Z',
		title: 'ООО "ЗомбиЛэнд"',
		contract: '2223341-АА',
		signedAt: '2021-10-19T13:23:24.000Z',
		note: 'Скидка 30%',
		userType: 'ur',
		bossFullName: 'Петров Иван Иванович',
		bossPhone: '+79285666366',
		bossEmail: 'DonStroyGrup@gmail.com',
		contactPerson: 'Иванов Александр Иванович',
		contactPhone: '+79285666366',
		contactEmail: 'DonStroyGrup@gmail.com',
		locationCity: 'Ростов--на-Дону',
		locationPostcode: '325659',
		locationAddress: 'Малиновского, 142',
		registrationCity: 'Ростов--на-Дону',
		registrationPostcode: '325659',
		registrationAddress: 'Малиновского, 142',
		organizationOGRN: '12345678910111213',
		organizationKPP: '12345678910111213',
		organizationAccountsList: [
			{
				organizationAccountId: '1',
				organizationAccountNumber: '12345678910111213',
				organizationAccountSpecial: false,
			},
		],
		bankName: 'ПАО "Сбербанк"',
		bankBIK: '12345678910111213',
		bankCreatedAt: '2021-10-19T13:23:24.000Z',
		bankAccountNumber: '12345678910111213',
		bankOGRN: '12345678910111213',
		bankINN: '12345678910111213',
		bankKPP: '12345678910111213',
	},
	{
		id: '10',
		createdAt: '2021-10-19T13:23:24.000Z',
		title: 'ООО "ЗомбиЛэнд"',
		contract: '2223341-АА',
		signedAt: '2021-10-19T13:23:24.000Z',
		note: 'Скидка 30%',
		userType: 'ur',
		bossFullName: 'Петров Иван Иванович',
		bossPhone: '+79285666366',
		bossEmail: 'DonStroyGrup@gmail.com',
		contactPerson: 'Иванов Александр Иванович',
		contactPhone: '+79285666366',
		contactEmail: 'DonStroyGrup@gmail.com',
		locationCity: 'Ростов--на-Дону',
		locationPostcode: '325659',
		locationAddress: 'Малиновского, 142',
		registrationCity: 'Ростов--на-Дону',
		registrationPostcode: '325659',
		registrationAddress: 'Малиновского, 142',
		organizationOGRN: '12345678910111213',
		organizationKPP: '12345678910111213',
		organizationAccountsList: [
			{
				organizationAccountId: '1',
				organizationAccountNumber: '12345678910111213',
				organizationAccountSpecial: false,
			},
		],
		bankName: 'ПАО "Сбербанк"',
		bankBIK: '12345678910111213',
		bankCreatedAt: '2021-10-19T13:23:24.000Z',
		bankAccountNumber: '12345678910111213',
		bankOGRN: '12345678910111213',
		bankINN: '12345678910111213',
		bankKPP: '12345678910111213',
	},
]

const archiveList : clientType[] = [
	{
		id: '1',
		createdAt: '2021-10-19T13:23:24.000Z',
		title: 'Batman 1 Incorporation',
		contract: '11111111SАN',
		signedAt: '2021-10-19T13:23:24.000Z',
		note: 'Особый клиент',
		userType: 'ur',
		bossFullName: 'Джон Джонсон',
		bossPhone: '+19999999999',
		bossEmail: 'JohnGroup@gmail.com',
		contactPerson: 'Джон Джонсон',
		contactPhone: '+19999999999',
		contactEmail: 'JohnGroup@gmail.com',
		locationCity: 'NYC',
		locationPostcode: '325659',
		locationAddress: '142 Brooklyn st',
		registrationCity: 'NYC',
		registrationPostcode: '325659',
		registrationAddress: '142 Brooklyn st',
		organizationOGRN: '*****************',
		organizationKPP: '*****************',
		organizationAccountsList: [
			{
				organizationAccountId: '1',
				organizationAccountNumber: '*****************',
				organizationAccountSpecial: false,
			},
		],
		bankName: 'ПАО "Сбербанк"',
		bankBIK: '12345678910111213',
		bankCreatedAt: '2021-10-19T13:23:24.000Z',
		bankAccountNumber: '*****************',
		bankOGRN: '*****************',
		bankINN: '*****************',
		bankKPP: '*****************',
	},
	{
		id: '2',
		createdAt: '2021-10-19T13:23:24.000Z',
		title: 'Batman 2 Incorporation"',
		contract: '11111111SАN',
		signedAt: '2021-10-19T13:23:24.000Z',
		note: 'Особый клиент',
		userType: 'fiz',
		bossFullName: 'Джон Джонсон',
		bossPhone: '+19999999999',
		bossEmail: 'JohnGroup@gmail.com',
		contactPerson: 'Джон Джонсон',
		contactPhone: '+19999999999',
		contactEmail: 'JohnGroup@gmail.com',
		locationCity: 'NYC',
		locationPostcode: '325659',
		locationAddress: '142 Brooklyn st',
		registrationCity: 'NYC',
		registrationPostcode: '325659',
		registrationAddress: '142 Brooklyn st',
		organizationOGRN: '*****************',
		organizationKPP: '*****************',
		organizationAccountsList: [
			{
				organizationAccountId: '1',
				organizationAccountNumber: '*****************',
				organizationAccountSpecial: false,
			},
		],
		bankName: 'ПАО "Сбербанк"',
		bankBIK: '*****************',
		bankCreatedAt: '2021-10-19T13:23:24.000Z',
		bankAccountNumber: '*****************',
		bankOGRN: '*****************',
		bankINN: '*****************',
		bankKPP: '*****************',
	},
]

const ratingsList: ratingsType[] =  [
	{ id: 1,
		name: 'Western Union',
		rating: 92,
		maxPeriod: 61,
		maxCount: 12000000,
		minBet: 2.68,
		commissionParticipation: 1000,
		commissionExecution: 1000,
		commissionGuarantee: 1000},
	{ id: 2,
		name: 'ПАО Сбербанк',
		rating: 92,
		maxPeriod: 112,
		maxCount: 50000000,
		minBet: 2.68,
		commissionParticipation: 1000,
		commissionExecution: 1000,
		commissionGuarantee: 1000},
	{ id: 3,
		name: 'ПАО Нефтяник',
		rating: 92,
		maxPeriod: 37,
		maxCount: 1000000000,
		minBet: 2.68,
		commissionParticipation: 1000,
		commissionExecution: 1000,
		commissionGuarantee: 1000},
	{ id: 4,
		name: 'МеталлИнвест',
		rating: 92,
		maxPeriod: 4,
		maxCount: 700000000,
		minBet: 2.68,
		commissionParticipation: 1000,
		commissionExecution: 1000,
		commissionGuarantee: 1000},
	{ id: 5,
		name: 'ПАО Газпром',
		rating: 5,
		maxPeriod: 191,
		maxCount: 1000000000,
		minBet: 2.68,
		commissionParticipation: 1000,
		commissionExecution: 1000,
		commissionGuarantee: 1000},
	{ id: 6,
		name: 'ПАО ПетроКоммерц',
		rating: 220,
		maxPeriod: 72,
		maxCount: 1000000000,
		minBet: 2.68,
		commissionParticipation: 1000,
		commissionExecution: 1000,
		commissionGuarantee: 1000},
	{ id: 7,
		name: 'ПАО Тинькофф',
		rating: 92,
		maxPeriod: 72,
		maxCount: 1000000000,
		minBet: 2.68,
		commissionParticipation: 1000,
		commissionExecution: 1000,
		commissionGuarantee: 1000},
	{ id: 8,
		name: 'ПАО АльфаБанк',
		rating: 92,
		maxPeriod: 72,
		maxCount: 1000000000,
		minBet: 2.68,
		commissionParticipation: 1000,
		commissionExecution: 1000,
		commissionGuarantee: 1000},
	{ id: 9,
		name: 'ПАО ВТБ',
		rating: 92,
		maxPeriod: 72,
		maxCount: 1000000000,
		minBet: 2.68,
		commissionParticipation: 1000,
		commissionExecution: 1000,
		commissionGuarantee: 1000},
	{ id: 10,
		name: 'ПАО Банк Москва',
		rating: 92,
		maxPeriod: 72,
		maxCount: 1000000000,
		minBet: 2.68,
		commissionParticipation: 1000,
		commissionExecution: 1000,
		commissionGuarantee: 1000},
	{ id: 11,
		name: 'ПАО Банк России',
		rating: 92,
		maxPeriod: 72,
		maxCount: 1000000000,
		minBet: 2.68,
		commissionParticipation: 1000,
		commissionExecution: 1000,
		commissionGuarantee: 1000},
	{ id: 12,
		name: 'ПАО Банк Открытие',
		rating: 92,
		maxPeriod: 72,
		maxCount: 1000000000,
		minBet: 2.68,
		commissionParticipation: 1000,
		commissionExecution: 1000,
		commissionGuarantee: 1000},
]

const EMAIL_REG = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export {
	CONVERT_SECONDS_TO_MONTHS,
	DAYS_IN_MONTH,
	YEAR,
	DEFAULT_RANGE,
	EMAIL_REG,
	banksList,
	playgroundsList,
	clientsList,
	archiveList,
	ratingsList
}
