import Popup from 'reactjs-popup'
import React, {useContext} from "react"
import { observer } from "mobx-react-lite"
import { StoreContext } from "../../../../StoreProvider"
import { CreateRequestForm } from '../../../CreateRequestForm/CreateRequestForm'
import './styles.scss'

export const BankItem = observer((props: any) => {
  const store = useContext(StoreContext)
  const {bank, commissionPrice, period} = props

  return (
    <div className="table-row">
      <div className="table-cell-bank">
        <div className="img-wrapper">
          <img src={bank.img} alt={bank.name}/>
        </div>
      </div>
      <div className="table-cell">
        <span className="table-cell-span">
          {`${commissionPrice} ₽`}
        </span>
        <Popup
          contentStyle={{ maxWidth: "280px", margin: "0 0 10px 10px", width: "500px", padding: "10px", borderRadius: "2px", backgroundColor: "#F0F9D4" }}
          trigger={
            <svg className="info-icon" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="9" fill="#B5C15D" />
              <path d="M12.988 9.888C12.988 10.7733 12.956 11.584 12.892 12.32C12.828 13.056 12.748 13.7973 12.652 14.544H11.276C11.1693 13.7973 11.084 13.056 11.02 12.32C10.956 11.5733 10.924 10.7627 10.924 9.888V6.912H12.988V9.888ZM13.228 16.944C13.228 17.3173 13.1053 17.6213 12.86 17.856C12.6147 18.0907 12.3107 18.208 11.948 18.208C11.596 18.208 11.292 18.0907 11.036 17.856C10.7907 17.6213 10.668 17.3173 10.668 16.944C10.668 16.5707 10.7907 16.2667 11.036 16.032C11.292 15.7867 11.596 15.664 11.948 15.664C12.3107 15.664 12.6147 15.7867 12.86 16.032C13.1053 16.2667 13.228 16.5707 13.228 16.944Z" fill="#F0F9D4" />
            </svg>
          }
          position={ window.document.documentElement.clientWidth > 768 ?
            "right center" : "left center" }
          on="hover"
          closeOnDocumentClick
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          arrow={false}
        >
          <div className="popup-info">
            <p>Стоимость банковской гарантии рассчитана предварительно и может быть уточнена банком при рассмотрении заявки
            </p>
          </div>
        </Popup>
      </div>
      <div className="table-cell">
        <span className="table-cell-span">
          {`${bank.minBet} % годовых`}
        </span>
      </div>
      <div className="table-cell">
        <div>
          <Popup
            contentStyle={{borderRadius: "6px", width: "auto"}}
            trigger={<button className="request-button"><span className="button-span">Оставить заявку</span></button>}
            modal
            nested
          >
            {(close: any) => (
              <CreateRequestForm close={close} title="Заявка на банковскую гарантию" bank={bank} period={period} total={store.banks.total} guarantee={store.banks.guarantee} commissionPrice={commissionPrice} />
            )}
          </Popup>
        </div>
      </div>
    </div>
  )
})
