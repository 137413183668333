import { Link } from 'react-router-dom';

import './path.scss';

function Path({ placeName, setPlaceParam }: any) {
  return (
    placeName ?
      <div className="dm-path">
        <div className="path">
          <Link onClick={() => setPlaceParam('gen')} to="/" className="path-general">Главная</Link>
        </div>
        <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="6.35023" height="0.705547" transform="matrix(-0.701667 -0.712505 0.709431 -0.704775 4.49951 5.02197)" fill="black" />
          <rect width="6.34992" height="0.705581" transform="matrix(0.709431 -0.704775 0.701667 0.712505 0 8.49707)" fill="black" />
        </svg>
        <div className="path-current">{placeName}</div>
      </div>
      : null
  )
}

export default Path;
