import React from 'react';

import PricesMenu from '../PricesMenu/PricesMenu';
import Path from '../Path/Path';
import {setOpen} from "../../utils";

import '../MainLayout/main-layout.scss';
import './trade.scss';
import '../../styles/tables.scss';

function Trade({ setPlaceParam }: any) {
  const part1 = [
    { id: '2', name: 'Регистрация в ЕИС + аккредитация на 8 федеральных ЭТП', price: '3000 рублей', text: 'Регистрация необходима для участия в закупках по 44-ФЗ и 223-ФЗ (по закупкам, в которых участниками могут быть только субъекты малого предпринимательства). Мы проверим полученные от вас документы и подадим заявку на регистрацию в единой информационной системе и на 8 электронных торговых площадках, отобранных для проведения закупок по 44-ФЗ' },
    { id: '3', name: 'Аккредитация на любой ЭТП', price: '500 рублей', text: 'Аккредитация необходима для участия в коммерческих закупках или закупках, проводимых по 223-ФЗ (за исключением закупок, в которых участниками могут быть только субъекты малого предпринимательства). Мы проверим полученные от вас документы и подадим заявку на аккредитацию на выбранной электронной торговой площадке' },
    { id: '4', name: 'Аккредитация на ЭТП АСТ ГОЗ', price: '8000 рублей', text: 'Регистрация необходима для участия в закрытых процедурах закупки, а также в закупках по гособоронзаказу. Мы проверим полученные от вас документы, настроим рабочее место и подключение к защищенной сети АСТ ГОЗ и подадим заявку на аккредитацию на электронной площадке' },
    { id: '5', name: 'Проверка документов, подтверждающих наличие опыта исполнения работ и подача запроса на их размещение на 8 федеральных ЭТП', price: 'от 4000 рублей*', text: 'Для участия в закупках по №44-ФЗ, в которых установлены требования к участникам закупки в соответствии с Постановлением Правительства РФ №99, необходимо предварительно зарегистрировать опыт исполнения работ на электронной торговой площадке. Мы проверим полученные от вас документы и разместим их на 8 электронных торговых площадках, отобранных для проведения закупок по 44-ФЗ'}
  ];

  const part2 = [
    { id: '6', name: 'Подготовка и подача заявки на участие', price: 'от 1000 рублей*', text: 'Мы проанализируем документацию о закупке, проверим полученные от вас документы, составим и подадим заявку на участие' },
    { id: '7', name: 'Подготовка технической части заявки (первая часть, форма 2)', price: 'от 2000 рублей*', text: 'В некоторых закупках установлены требования о предоставлении подробного описания характеристик материалов или товаров, применяемых при выполнении работ или оказании услуг. Мы подготовим такое описание в соответствии с требованиями заказчика и с учетом требований нормативных документов, которым должны соответствовать материалы или товары'},
    { id: '8', name: 'Участие в торгах', price: '500 рублей за один час торгов', text: 'Мы возьмём на контроль сроки начала торгов и сопроводим их, сколько бы они не длились. Имеем стабильный интернет с двойным резервированием и парк компьютеров, настроенных для работы на ЭТП. Сбои по нашей вине в ходе проведения торгов исключены' },
    { id: '9', name: 'Подписание контракта', price: '2500 рублей', text: 'Мы возьмём на контроль сроки подписания контракта, изучим контракт, направленный заказчиком для подписания, проконсультируем по способам обеспечения исполнения контракта и совершим все действия, необходимые для подписания контракта на электронной торговой площадке' }
  ];

  const part3 = [
    { id: '11', name: 'Составление жалобы в УФАС', price: 2000, text: 'Причин для обращения в антимонопольную службу может быть множество: неправомерные требования в документации, отклонение заявки, отклонение банковской гарантии, отказ от заключения контракта, односторонний отказ от исполнения контракта, неправомерные действия заказчика в ходе проведения закупки. Мы готовы изучить каждый случай, дать оценку правомерности действии заказчика и составить жалобу в антимонопольный орган' },
    { id: '12', name: 'Представление интересов на заседании комиссии УФАС', price: 4000, text: 'Мы представим ваши интересы в ходе заседания комиссии антимонопольной службы очно или посредством видео-конференц-связи' },
    { id: '13', name: 'Составление искового заявления', price: 5000, text: 'Мы подготовим исковое заявление на предмет оспаривания решений заказчика, комиссии антимонопольной службы, а также действий заказчика в ходе исполнения контракта или приёмки работ по нему' },
    { id: '14', name: 'Представление интересов в судебном заседании', price: 6000, text: 'Мы представим ваши интересы в ходе судебного заседания' }
  ];

  const [opened, setOpened] = React.useState([]);

  return (
    <section className="trade">
      <div className="container">
        <Path placeName={'Сопровождение участия'} setPlaceParam={setPlaceParam} />
        <div className="info-header">
          <h2>Сопровождение участия</h2>
        </div>
        <div className="info-block">
          <div className="info-pic">
            <img src="/trade.png" alt="торги" />
          </div>
          <div className="info-text">
            <p>
              Мы готовы предложить вашему бизнесу полноценный тендерный отдел со всеми
              необходимыми специалистами, которые сопроводят ваше участие в любых закупочных
              процедурах от получения электронной подписи и поиска подходящей вам закупки
              до заключения контракта
            </p>
            <p>
              Мы не берём процент от выигранной вами закупки, вы платите только за услуги,
              фактически оказанные в рамках сопровождения вашего участия
            </p>
            <p>
              Для закупок с небольшой начальной ценой у нас существует система скидок
            </p>
          </div>
        </div>
        <div className="trade-container">
          <div className="trade-header">
            <h2>Мы сопровождаем</h2>
            <h2>ваше участие:</h2>
          </div>
          <ul className="trade-list">
            <li>
              в закупках, проводимых в соответствии с положениями Федерального закона №44-ФЗ (госзакупки);</li>
            <li>
              в закупках, проводимых в соответствии с положениями Федерального закона №223-ФЗ (закупки госкомпаний, госкорпораций, субьектов естественных монополий, государственных и муниципальных унитарных предприятий, а также компаний в уставном капитале которых доля участия государства превышает 50%);
            </li>
            <li>
              в закупках, проводимых в соответствии с положениями Постановления Правительства РФ №615 (закупки на проведение капитального ремонта общего имущества в многоквартирном доме);</li>
            <li>
              в закупках малого объёма;</li>
            <li>
              в закупках на аренду и продажу земельных участков, государственного и муниципального имущества;</li>
            <li>
              в коммерческих закупках.</li>
          </ul>
        </div>
        <section className="trade-services">
          <PricesMenu />
          <p className="trade-table-header">Подготовка к участию в закупках</p>
          {part1.map(item => (
            <div key={item.id} className="ts-block">
              <div className="ts-name">{item.name}</div>
              <div className="mobile">
                <div className="mobile-price">{item.price}</div>
                <div onClick={() => setOpen(item.id, opened, setOpened)} className="mobile-price-selector">
                  <svg width="12" height="6"
                       transform={opened.find((o: string) => { return o === item.id }) ? "rotate(180)" : "rotate(0)"}
                       viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="0.961628" height="8.02536" transform="matrix(0.750663 -0.660685 0.743744 0.668464 0 0.635254)" fill="#C2C2C2" />
                    <rect width="0.960772" height="8.03518" transform="matrix(0.743744 0.668464 -0.750663 0.660685 11.2842 0.0490723)" fill="#C2C2C2" />
                  </svg>
                </div>
              </div>
              <div className={opened.find((o: string) => { return o === item.id }) ? "ts-service-active" : "ts-service"}>{item.text}</div>
              <div className="ts-price">{item.price}</div>
            </div>
          ))}
          <p className="trade-table-header">Участие в закупках</p>
          {part2.map(item =>
            (
              <div key={item.id} className="ts-block">
                <div className="ts-name">{item.name}</div>
                <div className="mobile">
                  <div className="mobile-price">от {item.price}</div>
                  <div onClick={() => setOpen(item.id, opened, setOpened)} className="mobile-price-selector">
                    <svg width="12" height="6"
                         transform={opened.find((o: string) => { return o === item.id }) ? "rotate(180)" : "rotate(0)"}
                         viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="0.961628" height="8.02536" transform="matrix(0.750663 -0.660685 0.743744 0.668464 0 0.635254)" fill="#C2C2C2" />
                      <rect width="0.960772" height="8.03518" transform="matrix(0.743744 0.668464 -0.750663 0.660685 11.2842 0.0490723)" fill="#C2C2C2" />
                    </svg>
                  </div>
                </div>
                <div className={opened.find((o: string) => { return o === item.id }) ? "ts-service-active" : "ts-service"}>{item.text}</div>
                <div className="ts-price">{item.price}</div>
              </div>
            ))}
          <p className="trade-table-header">Сопровождение в суде, антимонопольном органе</p>
          {part3.map(item =>
            (
              <div key={item.id} className="ts-block">
                <div className="ts-name">{item.name}</div>
                <div className="mobile">
                  <div className="mobile-price">от {item.price} руб.</div>
                  <div onClick={() => setOpen(item.id, opened, setOpened)} className="mobile-price-selector">
                    <svg width="12" height="6"
                         transform={opened.find((o: string) => { return o === item.id }) ? "rotate(180)" : "rotate(0)"}
                         viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="0.961628" height="8.02536" transform="matrix(0.750663 -0.660685 0.743744 0.668464 0 0.635254)" fill="#C2C2C2" />
                      <rect width="0.960772" height="8.03518" transform="matrix(0.743744 0.668464 -0.750663 0.660685 11.2842 0.0490723)" fill="#C2C2C2" />
                    </svg>
                  </div>
                </div>
                <div className={opened.find((o: string) => { return o === item.id }) ? "ts-service-active" : "ts-service"}>{item.text}</div>
                <div className="ts-price">от {item.price} рублей*</div>
              </div>
            ))}
          <div className="footnote">*Точную стоимость услуги вы можете узнать в день обращения, предоставив подробности нашему менеджеру.</div>
        </section>
        <div className="price-list">
          <span>Вы можете скачать полный прайс-лист наших услуг</span>
          <form action="/price.pdf" target="_blank">
            <input className="pl-button" type="submit" value="Скачать прайс" />
          </form>
        </div>
      </div>
    </section>
  )
}

export default Trade;
