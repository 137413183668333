function Phone(): JSX.Element {
  return (
    <div className="phone-component">
      <a href="tel:+79956158000" className="icon-phone">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.4999 17.8678C23.1799 17.5507 21.4731 16.1762 20.7263 15.6476C20.0862 15.2247 19.2328 14.696 18.6994 14.4846C18.0593 14.1674 17.3126 14.0617 16.6725 14.2731C16.1391 14.4846 15.9258 14.696 15.2857 15.4361C14.9657 15.7533 14.7523 16.0705 14.7523 16.1762C14.7523 16.1762 14.6456 16.2819 14.5389 16.2819C14.2189 16.3877 13.6855 16.2819 12.7254 15.859C11.872 15.4361 11.2319 14.9075 10.3785 14.1674C9.63172 13.533 8.99165 12.6872 8.56494 12.0529C8.03154 11.207 7.71151 10.1498 7.71151 9.72687C7.71151 9.51542 7.71151 9.19824 7.81818 9.09251L7.92486 8.98678C8.03154 8.88106 8.2449 8.77533 8.45826 8.56388C9.41836 7.82379 9.7384 7.50661 9.84508 6.97797C9.95176 6.44934 9.84508 5.81498 9.41836 4.96916C8.77829 3.70044 6.7514 0.951542 5.89797 0.422907C5.79129 0.317181 5.47125 0.211454 5.36458 0.105727C4.93786 2.36318e-08 4.40447 0 4.08443 0C3.65772 0.105727 3.12432 0.422908 2.05754 1.163C1.52414 1.5859 0.670716 2.43172 0.35068 2.85463C-0.182713 3.91189 -0.0760348 4.75771 0.457358 6.13216C1.20411 8.03524 2.16422 9.93833 3.33768 11.8414C3.55104 12.1586 3.65772 12.37 3.87108 12.6872C4.08443 13.0044 4.29779 13.2159 4.51115 13.533C6.43136 16.1762 8.77829 18.3965 11.4453 20.1938L11.5519 20.2996C11.6586 20.4053 11.872 20.511 11.9787 20.6167L12.2987 20.8282C12.512 20.9339 12.6187 21.0396 12.8321 21.1454L15.8191 22.837C16.0324 22.9427 16.2458 23.0485 16.4592 23.1542L16.7792 23.2599C17.0992 23.3656 17.4193 23.5771 17.7393 23.6828C18.3794 23.8943 18.6994 24 19.1261 24C19.2328 24 19.2328 24 19.3395 24C20.0862 24 20.7263 23.6828 21.4731 23.1542C22.3265 22.5198 23.4999 21.1454 23.82 20.2996C24.14 19.348 24.0333 18.5022 23.4999 17.8678Z" fill="#CCE271" />
        </svg>
      </a>
      <a href="tel:+79956158000" className="phone-number">+7 995 615 80 00</a>
    </div>
  )
}

export default Phone;
