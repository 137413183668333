import React, {createContext} from 'react'
import axios from 'axios'
import { useLocalObservable } from "mobx-react"
import { checkCommonInput, checkInputWithFormat, checkPasswordInput, checkPhoneInput } from './utils'
import {banksList} from "./components/FinServices/constants"
import {
  DEFAULT_RANGE,
  YEAR,
  DAYS_IN_MONTH,
  CONVERT_SECONDS_TO_MONTHS,
  EMAIL_REG,
} from './components/constants'
import {
  filterByMaxCount,
  filterByMaxPeriod,
  filterByMinBet,
  filterByRating
} from "./components/FinServices/BanksList/utils"

const BASE_URL = 'http://87.117.3.96:3088/API_1/'

const registerUser = async (data, onSuccess, setMessage, setError) => {
  const config = {
    method: 'post',
    url: `${BASE_URL}registration`,
    data,
  }
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        onSuccess(response.data)
      }
    })
    .catch((error) => {
      if (error.response) {
        setMessage(error.response.data.message)
        setError(true)
      }
      console.log('registration error', error)
    })
}

export const StoreContext = createContext()

const StoreProvider = ({ children }) => {
  const store = useLocalObservable(() => ({
    banks: {
      guarantee: '',
      total: '',
      commission: '',
      commissionPrice: '',
      isOpened: false,
      errTotal: '',
      errGuarantee: '',
      errPeriod: '',
      periodRange: DEFAULT_RANGE,
      fiveBanks: [],
      tenBanks: [],
      fiftyBanks: [],
      hundredBanks: [],
      twoHundredBanks: [],
      theFirstBank: {},
      theSecondBank: {},
      theThirdBank: {},
      theForthBank: {},
      theFifthBank: {},
      theFirstCommission: 0,
      theSecondCommission: 0,
      theThirdCommission: 0,
      theForthCommission: 0,
      theFifthCommission: 0,
      setBanksField: (name, value) => {
        store.banks[name] = value
      },
      getPeriodRange: (start, end) => {
        const endTime = (end.getTime() / 1000).toFixed(0)
        const startTime = (start.getTime() / 1000).toFixed(0)
        store.banks.periodRange = (parseInt(endTime) - parseInt(startTime)) / CONVERT_SECONDS_TO_MONTHS
      },
      setBanks: (name, minRating, maxRating) => {
        const banksFilteredByRating = filterByRating(banksList, minRating, maxRating)
        const banksFilteredByPeriod = filterByMaxPeriod(banksFilteredByRating, store.banks.periodRange)
        const banksFilteredByCount = filterByMaxCount(banksFilteredByPeriod, parseInt(store.banks.total.replace(/\s+/g, '')))
        store.banks[name] = filterByMinBet(banksFilteredByCount)
      },
      getCommissionType: () => {
        if (store.banks.guarantee === 'БГнО гарантийных обязательств') {
          store.banks.setBanksField('commission', 'commissionGuarantee')
        } else if (store.banks.guarantee === 'БГнО участия в закупке') {
          store.banks.setBanksField('commission','commissionParticipation')
        } else if (store.banks.guarantee === 'БГнО исполнения контракта') {
          store.banks.setBanksField('commission','commissionContract')
        }
      },
      getActiveBank: (position) => {
        switch (position) {
          case 1:
            return store.banks.fiveBanks.length > 0 ? store.banks.fiveBanks[0]
              : store.banks.tenBanks.length > 0 ? store.banks.tenBanks[0]
                : store.banks.fiftyBanks.length > 0 ? store.banks.fiftyBanks[0]
                  : store.banks.hundredBanks.length > 0 ? store.banks.hundredBanks[0]
                    : store.banks.twoHundredBanks[0]
          case 2:
            return store.banks.tenBanks.length > 0 ? store.banks.tenBanks[0]
              : store.banks.fiftyBanks.length > 0 ? store.banks.fiftyBanks[0]
                : store.banks.hundredBanks.length > 0 ? store.banks.hundredBanks[0]
                  : store.banks.twoHundredBanks[0]
          case 3:
            return store.banks.fiftyBanks.length > 0 ? store.banks.fiftyBanks[0]
              : store.banks.hundredBanks.length > 0 ? store.banks.hundredBanks[0]
                : store.banks.twoHundredBanks[0]
          case 4:
            return store.banks.hundredBanks.length > 0 ? store.banks.hundredBanks[0]
              : store.banks.twoHundredBanks[0]
          case 5:
            return store.banks.twoHundredBanks[0]
          default:
            return []
        }
      },
      getCommissionPrice: (bank) => {
        if (bank) {
          const amount = +store.banks.total.split(' ').join('')
          const commission = bank[store.banks.commission]
          if (amount > 0) {
            const totalAmount = Math.round(amount * (bank.minBet / 100) / YEAR * (store.banks.periodRange * DAYS_IN_MONTH))
            if (totalAmount < commission) {
              return commission.toString()
            } else {
              const parts = totalAmount.toString().split(".")
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              return parts.join(".")
            }
          } else return "0"
        } else return null
      },
      countBankGuarantee: () => {
        if (!store.banks.total) store.banks.setBanksField('errTotal', 'Укажите сумму')
        if (!store.banks.guarantee) store.banks.setBanksField('errGuarantee','Укажите тип банковской гарантии')
        if (store.banks.periodRange === DEFAULT_RANGE) store.banks.setBanksField('errPeriod','Укажите период')
        if (!store.banks.total || !store.banks.guarantee || store.banks.periodRange === DEFAULT_RANGE || store.banks.errTotal) {
        } else {
          store.banks.setBanksField('isOpened', true)
          store.banks.setBanks('fiveBanks', 0, 5)
          store.banks.setBanks('tenBanks',6, 10)
          store.banks.setBanks('fiftyBanks',11, 50)
          store.banks.setBanks('hundredBanks',51, 100)
          store.banks.setBanks('twoHundredBanks',101, 200)
      
          store.banks.setBanksField('theFirstBank', store.banks.getActiveBank(1))
          store.banks.setBanksField('theSecondBank', store.banks.getActiveBank(2))
          store.banks.setBanksField('theThirdBank', store.banks.getActiveBank(3))
          store.banks.setBanksField('theForthBank', store.banks.getActiveBank(4))
          store.banks.setBanksField('theFifthBank', store.banks.getActiveBank(5))
      
          store.banks.setBanksField('theFirstCommission', store.banks.getCommissionPrice(store.banks.theFirstBank))
          store.banks.setBanksField('theSecondCommission', store.banks.getCommissionPrice(store.banks.theSecondBank))
          store.banks.setBanksField('theThirdCommission', store.banks.getCommissionPrice(store.banks.theThirdBank))
          store.banks.setBanksField('theForthCommission', store.banks.getCommissionPrice(store.banks.theForthBank))
          store.banks.setBanksField('theFifthCommission', store.banks.getCommissionPrice(store.banks.theFifthBank))
        }
      },
    },
    registration: {
      userType: 'fiz',
      name: '',
      companyName: '',
      fio: '',
      contactPerson: '',
      phone: '',
      email: '',
      password: '',
      nameError: '',
      companyNameError: '',
      fioError: '',
      contactPersonError: '',
      phoneError: '',
      emailError: '',
      passwordError: '',
      userId: '',
      token: '',
      error: false,
      loading: false,
      message: '',
      get isFizValid() {
        return (
          store.registration.userType === 'fiz' &&
          store.registration.name &&
          store.registration.phone &&
          store.registration.email &&
          store.registration.password &&
          !store.registration.nameError &&
          !store.registration.phoneError &&
          !store.registration.emailError &&
          !store.registration.passwordError
        )
      },
      get isUrValid() {
        return (
          store.registration.userType === 'ur' &&
          store.registration.companyName &&
          store.registration.contactPerson &&
          store.registration.phone &&
          store.registration.email &&
          store.registration.password &&
          !store.registration.companyNameError &&
          !store.registration.contactPersonError &&
          !store.registration.phoneError &&
          !store.registration.emailError &&
          !store.registration.passwordError
        )
      },
      get isIpValid() {
        return (
          store.registration.userType === 'ip' &&
          store.registration.fio &&
          store.registration.contactPerson &&
          store.registration.phone &&
          store.registration.email &&
          store.registration.password &&
          !store.registration.fioError &&
          !store.registration.contactPersonError &&
          !store.registration.phoneError &&
          !store.registration.emailError &&
          !store.registration.passwordError
        )
      },
      get dataToSend() {
        if (store.registration.userType === 'fiz') {
          return {
            userType: store.registration.userType,
            name: store.registration.name,
            phone: store.registration.phone,
            email: store.registration.email,
            password: store.registration.password,
          }
        } else if (store.registration.userType === 'ur') {
          return {
            userType: store.registration.userType,
            companyName: store.registration.companyName,
            contactPerson: store.registration.contactPerson,
            phone: store.registration.phone,
            email: store.registration.email,
            password: store.registration.password,
          }
        } else {
          return {
            userType: store.registration.userType,
            fio: store.registration.fio,
            contactPerson: store.registration.contactPerson,
            phone: store.registration.phone,
            email: store.registration.email,
            password: store.registration.password,
          }
        }
      },
      setRegistrationField: (name, value) => {
        store.registration[name] = value
      },
      setPhone: (e) => {
        store.registration.phone = e
      },
      onSuccess: (data) => {
        store.registration.loading = false
        store.registration.error = false
        store.registration.userId = data.response.generalData.userId
        store.registration.token = data.response.sessionToken
        store.auth.saveCookies(
          data.response.generalData.userId,
          data.response.sessionToken,
        )
      },
      onFail: (data) => {
        store.registration.message = data.message
        store.registration.loading = false
        store.registration.error = true
      },
      setError: (flag) => {
        store.registration.error = flag
      },
      setMessage: (message) => {
        store.registration.message = message
      },
      checkNameValid: () => checkCommonInput(store.registration.name, 'nameError', store.registration.setRegistrationField),
      checkCompanyNameValid: () => checkCommonInput(store.registration.companyName, 'companyNameError', store.registration.setRegistrationField),
      checkContactPersonValid: () => checkCommonInput(store.registration.contactPerson, 'contactPersonError', store.registration.setRegistrationField),
      checkEmailValid: () => checkInputWithFormat(store.registration.email, EMAIL_REG, 'emailError', store.registration.setRegistrationField),
      checkPhoneValid: () => checkPhoneInput(store.registration.phone, 'phoneError', store.registration.setRegistrationField),
      checkPasswordValid: () => checkPasswordInput(store.registration.password, 'passwordError', store.registration.setRegistrationField),
      clearError: (errorName) => {
        store.registration.setRegistrationField(errorName, '')
      },
      submitRegistration: () => {
        store.registration.loading = true
        if (store.registration.isFizValid || store.registration.isUrValid || store.registration.isIpValid) {
          try {
            registerUser(
              store.registration.dataToSend,
              store.registration.onSuccess,
              store.registration.setMessage,
              store.registration.setError
            )} catch {
            store.registration.onFail()
          }
        } else return null
      },
    },
  }))
  
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  )
}

export default StoreProvider
