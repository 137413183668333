import { Link } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import '../MainLayout/main-layout.scss'
import './desktopmenu.scss';

function DesktopMenu({ placeParam, setPlaceParam }: any) {
  return (
    <section className="header-desktop-menu">
      <div className="container">
        <div className="hdm-menu">
          <div className="hdm-lk">
            <Link to="/about"
                  onClick={() => setPlaceParam('about')}
                  className={placeParam === 'about' ? 'menu-item-active' : 'menu-item'}
            >
              О компании
            </Link>
            <Link to="/esignature"
                  onClick={() => setPlaceParam('esignature')}
                  className={placeParam === 'esignature' ? 'd-item-active' : 'd-item'}
            >
              Электронная подпись
            </Link>
            <Link to="/monitoring"
                  onClick={() => setPlaceParam('monitoring')}
                  className={placeParam === 'monitoring' ? 'd-item-active' : 'd-item'}
            >
              Мониторинг закупок
            </Link>
            <Link to="/trade"
                  onClick={() => setPlaceParam('trade')}
                  className={placeParam === 'trade' ? 'd-item-active' : 'd-item'}
            >
              Сопровождение участия
            </Link>
            <Link to="/finservices"
                  onClick={() => setPlaceParam('finservices')}
                  className={placeParam === 'finservices' ? 'd-item-active' : 'd-item'}
            >
              Финансовые услуги
            </Link>
          </div>
          <Link to="/Lk"
                onClick={() => setPlaceParam('privacy_office')}
                className={placeParam === 'privacy_office' ? 'menu-item-active' : 'menu-item'}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4C13.0609 4 14.0783 4.42143 14.8284 5.17157C15.5786 5.92172 16 6.93913 16 8C16 9.06087 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12C10.9391 12 9.92172 11.5786 9.17157 10.8284C8.42143 10.0783 8 9.06087 8 8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4ZM12 14C16.42 14 20 15.79 20 18V20H4V18C4 15.79 7.58 14 12 14Z" fill="#CCE271" />
            </svg>
            Личный кабинет</Link>
        </div>
      </div>
    </section>
  );
}

export default DesktopMenu;
