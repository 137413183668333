import React from 'react';

// import GoogleMapReact from 'google-map-react';
import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps';

function MapContainer() {
  return (
    <div className="map">
      <YMaps>
        <Map
          defaultState={{
            center: [47.23033150, 39.68189906],
            zoom: 16,
            maxZoom: 18,
            minZoom: 13,
            behaviors: [
              'zoomControl'
            ]
          }}
          style={{ height: '70vh' }}
        >
          <ZoomControl />
          <Placemark
            geometry={[47.230388, 39.681838]}
            properties={{
              hintContent: [
                '<div className="map-info">' +
                '<strong>Россия, г.Ростов - на - Дону</strong>' +
                '<br/>' +
                '<strong>ул.Текучева 37, офис 300 Г</strong>' +
                '<br/>' +
                'пн-пт с 8:00 до 17:00' +
                '<br/>' +
                'сб-вс выходной' +
                '</div>'
              ]
            }}
            modules={['geoObject.addon.hint']}
            options={{
              maxHeight: 133,
              maxWidth: 300,
              iconColor: '#7A932C'
            }}
          />
        </Map>
      </YMaps>
    </div>
  )
}

export default MapContainer;
