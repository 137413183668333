import React from 'react';

import {setOpen} from "../../utils";
import PricesMenu from '../PricesMenu/PricesMenu';
import Path from '../Path/Path';

import './monitoring.scss';
import '../../styles/tables.scss';

function Monitoring({ setPlaceParam }: any) {
  const [opened, setOpened] = React.useState<string[]>([]);

  return (
    <section className="monitoring">
      <div className="container">
        <Path placeName={'Мониторинг закупок'} setPlaceParam={setPlaceParam} />
        <div className="info-header">
          <h2>Мониторинг закупок</h2>
        </div>
        <div className="info-block">
          <div className="info-pic">
            <img src="/monitoring.png" alt="мониторинг" />
          </div>
          <div className="info-text">
            <p>
              Мы проведем квалифицированный подбор государственных и коммерческих закупок по интересующим ваш бизнес параметрам: география поиска, предметная область, диапазон начальной цены контракта и другие.
            </p>
            <p>
              Мы изучим результат поиска нашей автоматизированной системы и оставим только те закупки, которые лучше всего подходят под ваш запрос.
            </p>
          </div>
        </div>
        <div className="monitoring-services">
          <PricesMenu />
          <div className="ts-block">
            <div className="ts-name">Разовый мониторинг</div>
            <div className="mobile">
              <div className="mobile-price">от 500 рублей*</div>
              <div onClick={() => setOpen('2', opened, setOpened)} className="mobile-price-selector">
                <svg width="12" height="6"
                     transform={opened.find((o: string) => { return o === '2' }) ? "rotate(180)" : "rotate(0)"}
                     viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="0.961628" height="8.02536" transform="matrix(0.750663 -0.660685 0.743744 0.668464 0 0.635254)" fill="#C2C2C2" />
                  <rect width="0.960772" height="8.03518" transform="matrix(0.743744 0.668464 -0.750663 0.660685 11.2842 0.0490723)" fill="#C2C2C2" />
                </svg>
              </div>
            </div>
            <div
              className={opened.find((o: string) => {
                return o === '2' }) ? "ts-service-active" : "ts-service"}
            >Срок подготовки - 1 рабочий день</div>
            <div className="ts-price">от 500 рублей*</div>
          </div>
          <div className="ts-block">
            <div className="ts-name">Периодический мониторинг</div>
            <div className="mobile">
              <div className="mobile-price">от 1000 рублей*</div>
              <div onClick={() => setOpen('3', opened, setOpened)} className="mobile-price-selector">
                <svg width="12" height="6"
                     transform={opened.find((o: string) => { return o === '3' }) ? "rotate(180)" : "rotate(0)"}
                     viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="0.961628" height="8.02536" transform="matrix(0.750663 -0.660685 0.743744 0.668464 0 0.635254)" fill="#C2C2C2" />
                  <rect width="0.960772" height="8.03518" transform="matrix(0.743744 0.668464 -0.750663 0.660685 11.2842 0.0490723)" fill="#C2C2C2" />
                </svg>
              </div>
            </div>
            <div
              className={opened.find((o: string) => {
                return o === '3' }) ? "ts-service-active" : "ts-service"}
            >Периодичность предоставления информации: еженедельно в понедельник, среду и пятницу</div>
            <div className="ts-price">от 1000 рублей*</div>
          </div>
          <div className="footnote">*Точную стоимость услуги вы можете узнать в день обращения, предоставив подробности нашему менеджеру.</div>
        </div>
      </div>
    </section>
  )
}

export default Monitoring;
