import React from "react"
import "./button.scss"


export const Button = (props: any) => {
  const { text, className, onClick } = props

  const handleClick = (e: Event) => {
    e.preventDefault()
  }

  return (
    <button
      onClick={onClick ? onClick : handleClick}
      className={className ? className : 'button'}
    >
      {text}
    </button>
  )
}
