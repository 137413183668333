import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import ContactUs from '../ContactUs/ContactUs';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './Services.scss';

const services = [
  { "id": "0", "header": "Электронная подпись", "text": "Подберём подходящую электронную подпись и сопроводим её выпуск", url: '/esignature' },
  { "id": "1", "header": "Мониторинг закупок", "text": "Подберём закупки, которые будут полностью отвечать специализации вашей компании", url: '/monitoring' },
  { "id": "2", "header": "Сопровождение вашего бизнеса", "text": "Сопроводим участие вашей компании в любых закупках на всех этапах их проведения", url: '/trade' },
  { "id": "3", "header": "Финансовое сопровождение", "text": "Подберём лучшие условия банковской гарантии, тендерного займа или кредита на исполнение контракта среди нескольких десятков банков-партнёров", url: '/finservices' },
];

const DELAY = 4000

const MainPage = () => {
  const [isService, setService] = useState(services[0]);
  const [isTimerMade, setTimerMade] = useState<boolean>(true);
  const timer = useRef(0);

  const getNextService = useCallback(() => {
    if (isService.id === "3") {
      setService(services[0]);
    } else {
      const nextService: number = (+isService.id + 1);
      setService(services[nextService]);
    }
  }, [isService])

  const startTimer = useCallback(() => {
    timer.current = window.setTimeout(() => getNextService(), DELAY);
  }, [timer])

  const clearTimer = () => clearTimeout(timer.current)

  const onHandleChangeServiceItem = (currService: any) => {
    const nextService = services.find(i => i.id === currService);
    clearTimer();
    if (nextService) {
      setService(nextService);
    }
  }

  useEffect(() => {
    if (isTimerMade) startTimer();
    if (!isTimerMade) clearTimer();
  },[isTimerMade, isService]);

  const [link, getLink] = useState<string>('/esignature');
  const setLink = (target: number) => {
    switch (target) {
      case 0:
        getLink('/esignature');
        break;
      case 1:
        getLink('/finservices');
        break;
      case 2:
        getLink('/trade');
        break;
      case 3:
        getLink('/monitoring');
        break;

      default: getLink('/esignature');
        break;
    }
  }

  return (
    <div className="main-page">
      <div className="container">
        <section className="services">
          <div className="cube1" />
          <div className="white" />
          <div className="videosection">
            <video className="videoAutoPlay" muted autoPlay loop width="100%" height="auto" preload="auto" id="stkVideo">
              <source src="/stkvideo.mp4" type="video/mp4" />
            </video>
          </div>
          <div>
            <div className="desktop-slider">
              <div
                className="slider-numbers"
                onMouseOver={() => {setTimerMade(false)}}
                onMouseOut={() => setTimerMade(true)}
              >
                {services.map(service => (
                  <div
                    key={service.id}
                    className={isService.id === service.id ? "item-number-active" : "item-number"}
                    onMouseOver={() => onHandleChangeServiceItem(service.id)}
                  >
                    0{+service.id + 1}
                  </div>
                ))}
              </div>
              <div className="item">
                <div className="service">
                  <div className="service-header">{isService.header}</div>
                  <p>{isService.text}</p>
                </div>
              </div>
            </div>
            <div>
              <Link to={isService.url}><div className="services-button">Узнать подробнее</div></Link>
            </div>
          </div>
          <div className="mobile-slider">
            <Slider
              dots={true}
              infinite={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              autoplay={true}
              responsive={[
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                  }
                }
              ]}
              customPaging={(i) => (
                <p>0{i + 1}</p>
              )}
              afterChange={(e: number) => { setLink(e) }}
            >
              <div className="slider-item">
                <h3 className="slider-header">Электронная подпись</h3>
                <p className="slider-text">
                  Подберём подходящую электронную подпись и сопроводим её выпуск
                </p>
              </div>
              <div className="slider-item">
                <h3 className="slider-header">Мониторинг закупок</h3>
                <p className="slider-text">
                  Подберём закупки, которые будут полностью отвечать специализации вашей компании
                </p>
              </div>
              <div className="slider-item">
                <h3 className="slider-header">Сопровождение вашего бизнеса</h3>
                <p className="slider-text">
                  Сопроводим участие вашей компании в любых закупках на всех этапах их проведения
                </p>
              </div>
              <div className="slider-item">
                <h3 className="slider-header">Финансовое сопровождение</h3>
                <p className="slider-text">
                  Подберём лучшие условия банковской гарантии, тендерного займа или кредита на исполнение контракта среди нескольких десятков банков-партнёров
                </p>
              </div>
            </Slider>
            <Link to={link} className="slider-button">Узнать подробнее</Link>
          </div>
          <div className="green" />
          <div className="cube2" />
        </section>
        <section className="reasons">
          <div className="reasons-header">
            <p className="reasons-quantity">5</p>
            <p>Причин работы с нами</p>
          </div>
          <div className="reasons-points">
            <div className="point">
              <p className="point-text">
                Мы предлагаем комплексное сопровождение участия вашего бизнеса в публичных закупках по принципу «одного окна» от получения электронной подписи до заключения контракта.
              </p>
              <div className="point-number">01</div>
            </div>
            <div className="point">
              <p className="point-text">
                Наша команда постоянно отслеживает все изменения в законодательстве, связанном с торгами, что позволяет всегда быть в курсе нововведений.
              </p>
              <div className="point-number">02</div>
            </div>
            <div className="point">
              <p className="point-text">
                Наша квалификация позволяет разобраться в самых сложных и запутанных закупках, что гарантирует практически 100% вероятность допуска вашей заявки.
              </p>
              <div className="point-number">03</div>
            </div>
            <div className="point">
              <p className="point-text">
                Работа с нами, как правило, выгоднее содержания в штате нескольких собственных специалистов.
              </p>
              <div className="point-number">04</div>
            </div>
            <div className="point">
              <p className="point-text">
                Наша компания работает дистанционно по всей России.
              </p>
              <div className="point-number">05</div>
            </div>
          </div>
        </section>
        <ContactUs />
      </div>
    </div>
  )
}

export default MainPage;
