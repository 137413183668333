type IBanks = {
  id: number,
  img: string
}

type IRating = {
  id: number,
  name: string,
  img: string,
  rating: number,
  maxPeriod: number,
  maxCount: number,
  minBet: number,
  commissionParticipation: number,
  commissionContract: number,
  commissionGuarantee: number,
}

const banksList: IRating[] = [
  {id: 1, name: 'Абсолют Банк', img: '/bank-logos/small-logos/AbsoluteBankSmall.svg', rating: 32, maxPeriod: 72, maxCount: 60000000, minBet: 2.68, commissionParticipation: 1000, commissionContract: 1000, commissionGuarantee: 1000},
  {id: 2, name: 'АК БАРС', img: '/bank-logos/small-logos/AkBarsBankSmall.png', rating: 21, maxPeriod: 67, maxCount: 100000000, minBet: 2.9, commissionParticipation: 2500, commissionContract: 2500, commissionGuarantee: 2500},
  {id: 3, name: 'Альфа-Банк', img: '/bank-logos/small-logos/AlfaBankSmall.png', rating: 5, maxPeriod: 60, maxCount: 70000000, minBet: 3, commissionParticipation: 1000, commissionContract: 1000, commissionGuarantee: 1000},
  {id: 4, name: 'Банк Зенит', img: '/bank-logos/small-logos/BankZenitSmall.png', rating: 35, maxPeriod: 37, maxCount: 30000000, minBet: 3, commissionParticipation: 1000, commissionContract: 1000, commissionGuarantee: 1000},
  {id: 5, name: 'Банк Открытие', img: '/bank-logos/small-logos/BankOtkrytieSmall.png', rating: 8, maxPeriod: 36, maxCount: 150000000, minBet: 2.9, commissionParticipation: 5000, commissionContract: 5000, commissionGuarantee: 5000},
  {id: 6, name: 'Банк Уралсиб', img: '/bank-logos/small-logos/UralSibSmall.png', rating: 22, maxPeriod: 40, maxCount: 50000000, minBet: 2.8, commissionParticipation: 2500, commissionContract: 3000, commissionGuarantee: 3500},
  {id: 7, name: 'БКС Банк', img: '/bank-logos/small-logos/BSKSmall.png', rating: 73, maxPeriod: 40, maxCount: 40000000, minBet: 2.9, commissionParticipation: 1000, commissionContract: 1000, commissionGuarantee: 1000},
  {id: 8, name: 'Внешфинбанк', img: '/bank-logos/small-logos/VneshFinBankSmall.png', rating: 267, maxPeriod: 24, maxCount: 6000000, minBet: 3.2, commissionParticipation: 2500, commissionContract: 2500, commissionGuarantee: 2500},
  {id: 9, name: 'ВТБ', img: '/bank-logos/small-logos/VtbSmall.png', rating: 2, maxPeriod: 36, maxCount: 30000000, minBet: 3, commissionParticipation: 3000, commissionContract: 3500, commissionGuarantee: 3500},
  {id: 10, name: 'Газпромбанк', img: '/bank-logos/small-logos/GazpromBankSmall.png', rating: 3, maxPeriod: 62, maxCount: 100000000, minBet: 3.1, commissionParticipation: 2000, commissionContract: 2000, commissionGuarantee: 2000},
  {id: 11, name: 'Газтрансбанк', img: '/bank-logos/small-logos/GazTransBankSmall.png', rating: 207, maxPeriod: 24, maxCount: 7000000, minBet: 1.8, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 12, name: 'ДелоБанк', img: '/bank-logos/small-logos/DeloBankSmall.png', rating: 222, maxPeriod: 36, maxCount: 30000000, minBet: 2.2, commissionParticipation: 2000, commissionContract: 2000, commissionGuarantee: 2000},
  {id: 13, name: 'Держава', img: '/bank-logos/small-logos/DerzavaSmall.png', rating: 105, maxPeriod: 60, maxCount: 150000000, minBet: 3, commissionParticipation: 1000, commissionContract: 5000, commissionGuarantee: 5000},
  {id: 14, name: 'Инбанк', img: '/bank-logos/small-logos/InBankSmall.png', rating: 126, maxPeriod: 38, maxCount: 14000000, minBet: 2.8, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 15, name: 'Киви', img: '/bank-logos/small-logos/QiwiSmall.png', rating: 103, maxPeriod: 66, maxCount: 100000000, minBet: 3, commissionParticipation: 2000, commissionContract: 2000, commissionGuarantee: 2000},
  {id: 16, name: 'Кредит Европа Банк', img: '/bank-logos/small-logos/CreditEuropeSmall.png', rating: 53, maxPeriod: 60, maxCount: 35000000, minBet: 3.3, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 17, name: 'Кредит Урал Банк', img: '/bank-logos/small-logos/BankKubAOSmall.png', rating: 102, maxPeriod: 36, maxCount: 25000000, minBet: 3.3, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 18, name: 'Кубань Кредит', img: '/bank-logos/small-logos/kubanSmall.png', rating: 54, maxPeriod: 60, maxCount: 35000000, minBet: 3, commissionParticipation: 2500, commissionContract: 2500, commissionGuarantee: 2500},
  {id: 19, name: 'Локо-Банк', img: '/bank-logos/small-logos/LokoBankSmall.svg', rating: 42, maxPeriod: 36, maxCount: 15000000, minBet: 2.8, commissionParticipation: 2000, commissionContract: 2000, commissionGuarantee: 2000},
  {id: 20, name: 'Металлинвестбанк', img: '/bank-logos/small-logos/MetallInvestSmall.png', rating: 49, maxPeriod: 60, maxCount: 100000000, minBet: 2.5, commissionParticipation: 1500, commissionContract: 1500, commissionGuarantee: 1500},
  {id: 21, name: 'Модульбанк', img: '/bank-logos/small-logos/MetallInvestSmall.png', rating: 125, maxPeriod: 61, maxCount: 11400000, minBet: 2.5, commissionParticipation: 2000, commissionContract: 2000, commissionGuarantee: 2000},
  {id: 22, name: 'Московский Индустриальный Банк', img: '/bank-logos/small-logos/MinbankSmall.png', rating: 33, maxPeriod: 62, maxCount: 42400000, minBet: 3, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 23, name: 'Московский Кредитный Банк', img: '/bank-logos/small-logos/MKBSmall.png', rating: 7, maxPeriod: 26, maxCount: 5000000, minBet: 3, commissionParticipation: 2000, commissionContract: 2000, commissionGuarantee: 2000},
  {id: 24, name: 'МСП банк', img: '/bank-logos/small-logos/MSPSmall.png', rating: 46, maxPeriod: 999, maxCount: 1000000000, minBet: 3, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 25, name: 'МТС банк', img: '/bank-logos/small-logos/MTSSmall.png', rating: 36, maxPeriod: 36, maxCount: 30000000, minBet: 3.2, commissionParticipation: 2000, commissionContract: 2000, commissionGuarantee: 3000},
  {id: 26, name: 'ОТП банк', img: '/bank-logos/small-logos/OtpBankSmall.png', rating: 47, maxPeriod: 36, maxCount: 50000000, minBet: 2.15, commissionParticipation: 2500, commissionContract: 1500, commissionGuarantee: 2500},
  {id: 27, name: 'Первоуральскбанк', img: '/bank-logos/small-logos/PervouralskBankSmall.png', rating: 233, maxPeriod: 12, maxCount: 100000, minBet: 3.2, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 28, name: 'Пойдем!', img: '/bank-logos/small-logos/PoydemSmall.png', rating: 164, maxPeriod: 13, maxCount: 1000000, minBet: 2.8, commissionParticipation: 1000, commissionContract: 1000, commissionGuarantee: 1000},
  {id: 29, name: 'Примсоцбанк', img: '/bank-logos/small-logos/PrimSocBankSmall.png', rating: 70, maxPeriod: 29, maxCount: 8000000, minBet: 3.1, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 30, name: 'Промсвязьбанк', img: '/bank-logos/small-logos/PromSvyazBankSmall.svg', rating: 10, maxPeriod: 38, maxCount: 250000000, minBet: 3, commissionParticipation: 2300, commissionContract: 2300, commissionGuarantee: 2300},
  {id: 31, name: 'РГС банк', img: '/bank-logos/small-logos/RosGosStrahSmall.png', rating: 64, maxPeriod: 36, maxCount: 100000000, minBet: 2.9, commissionParticipation: 1500, commissionContract: 2500, commissionGuarantee: 2500},
  {id: 32, name: 'Реалист Банк', img: '/bank-logos/small-logos/RealistBankSmall.png', rating: 157, maxPeriod: 63, maxCount: 19000000, minBet: 3, commissionParticipation: 2000, commissionContract: 2000, commissionGuarantee: 2000},
  {id: 33, name: 'РЕСО кредит', img: '/bank-logos/small-logos/BankResoSmall.png', rating: 140, maxPeriod: 60, maxCount: 15000000, minBet: 3.2, commissionParticipation: 3000, commissionContract: 2500, commissionGuarantee: 2000},
  {id: 34, name: 'Росбанк', img: '/bank-logos/small-logos/RosBankSmall.svg', rating: 11, maxPeriod: 38, maxCount: 30000000, minBet: 3, commissionParticipation: 2500, commissionContract: 2500, commissionGuarantee: 2500},
  {id: 35, name: 'Россия', img: '/bank-logos/small-logos/BankRossiiSmall.svg', rating: 13, maxPeriod: 38, maxCount: 100000000, minBet: 3.2, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 36, name: 'Руснарбанк', img: '/bank-logos/small-logos/RusNarSmall.png', rating: 166, maxPeriod: 60, maxCount: 14000000, minBet: 3.3, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 37, name: 'Русский Стандарт', img: '/bank-logos/small-logos/RussStandartBankSmall.png', rating: 31, maxPeriod: 37, maxCount: 20000000, minBet: 3.1, commissionParticipation: 2500, commissionContract: 2500, commissionGuarantee: 2500},
  {id: 38, name: 'Санкт-Петербург', img: '/bank-logos/small-logos/BankSanktPeterburgSmall.svg', rating: 17, maxPeriod: 62, maxCount: 200000000, minBet: 3.5, commissionParticipation: 2500, commissionContract: 2500, commissionGuarantee: 2500},
  {id: 39, name: 'Саровбизнесбанк', img: '/bank-logos/small-logos/SarovBizhesSmall.png', rating: 135, maxPeriod: 38, maxCount: 25000000, minBet: 2.9, commissionParticipation: 2500, commissionContract: 1500, commissionGuarantee: 3000},
  {id: 40, name: 'Сбер Банк', img: '/bank-logos/small-logos/SberSmall.svg', rating: 1, maxPeriod: 37, maxCount: 50000000, minBet: 3.45, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 41, name: 'СДМ-Банк', img: '/bank-logos/small-logos/SDMSmall.png', rating: 77, maxPeriod: 60, maxCount: 10000000, minBet: 3.3, commissionParticipation: 2500, commissionContract: 2500, commissionGuarantee: 2500},
  {id: 42, name: 'Севергазбанк', img: '/bank-logos/small-logos/SeverGazBankSmall.png', rating: 86, maxPeriod: 30, maxCount: 23000000, minBet: 2.8, commissionParticipation: 2500, commissionContract: 2500, commissionGuarantee: 2500},
  {id: 43, name: 'СМП Банк', img: '/bank-logos/small-logos/SMPBankSmall.png', rating: 19, maxPeriod: 37, maxCount: 50000000, minBet: 3.2, commissionParticipation: 2500, commissionContract: 2500, commissionGuarantee: 2500},
  {id: 44, name: 'Совкомбанк', img: '/bank-logos/small-logos/SovkomSmall.png', rating: 9, maxPeriod: 50, maxCount: 200000000, minBet: 3.7, commissionParticipation: 1000, commissionContract: 1000, commissionGuarantee: 1000},
  {id: 45, name: 'Союз', img: '/bank-logos/small-logos/SouzBankSmall.png', rating: 67, maxPeriod: 62, maxCount: 15000000, minBet: 3, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 45, name: 'Столичный кредит', img: '/bank-logos/small-logos/BSKSmall.png', rating: 333, maxPeriod: 60, maxCount: 50000000, minBet: 2.9, commissionParticipation: 2500, commissionContract: 2500, commissionGuarantee: 2500},
  {id: 46, name: 'Тинькофф банк', img: '/bank-logos/small-logos/TinkoffBankSmall.svg', rating: 15, maxPeriod: 15, maxCount: 2000000, minBet: 3.5, commissionParticipation: 1000, commissionContract: 1000, commissionGuarantee: 1000},
  {id: 47, name: 'Транскапиталбанк', img: '/bank-logos/small-logos/TransKapitalSmall.png', rating: 38, maxPeriod: 60, maxCount: 20000000, minBet: 2.8, commissionParticipation: 2500, commissionContract: 2500, commissionGuarantee: 2500},
  {id: 48, name: 'Экспобанк', img: '/bank-logos/small-logos/AcspoBankSmall.png', rating: 61, maxPeriod: 60, maxCount: 25000000, minBet: 3.2, commissionParticipation: 3000, commissionContract: 3000, commissionGuarantee: 3000},
  {id: 49, name: 'SBI Банк', img: '/bank-logos/small-logos/SBISmall.png', rating: 151, maxPeriod: 36, maxCount: 30000000, minBet: 2.3, commissionParticipation: 2000, commissionContract: 2000, commissionGuarantee: 2000},
]

type IOptions ={
  value: string,
  label: string,
  className: string
}

type IPart1 = {
  id: string,
  name: string,
  price: string,
  text: string
}

const options: IOptions[] = [
  { value: 'БГнО участия в закупке', label: "Банковская гарантия на обеспечение участия в закупке", className: "bg-option" },
  { value: 'БГнО исполнения контракта', label: "Банковская гарантия на обеспечение исполнения контракта", className: "bg-option" },
  { value: 'БГнО гарантийных обязательств', label: "Банковская гарантия на обеспечение гарантийных обязательств", className: "bg-option" }
];

const defaultOption: string = 'Выберите вид банковской гарантии';
const defaultOption1: string = 'Выберите вид БГ';

const part1: IPart1[] = [
  { id: '01', name: 'Банковская гарантия на обеспечение участия в закупке', price: 'От 1,8% годовых от суммы обеспечения', text: 'Требуется для подачи заявки на участие в закупке' },
  { id: '02', name: 'Банковская гарантия на обеспечение исполнения контракта', price: 'От 1,8% годовых от суммы обеспечения', text: 'Требуется для заключения контракта с победителем закупки' },
  { id: '03', name: 'Банковская гарантия на обеспечение гарантийных обязательств', price: 'От 1,8% годовых от суммы обеспечения', text: 'Требуется для завершения работ по контракту и подписания заключительных актов' },
  { id: '04', name: 'Тендерный займ на участие', price: 'от 2,5% от суммы займа', text: 'Требуется для подачи заявки на участие в закупке. Заменяет банковскую гарантию, в случае, когда её получение по какой-то причине невозможно' },
  { id: '05', name: 'Кредит на исполнение контракта', price: 'от 10% годовых от суммы кредита', text: 'Позволяет пополнить оборотные средства, когда их не хватает для исполнения контракта' },
];

const banks: IBanks[] = [
  { id: 1, img: '/bank-logos/small-logos/SberSmall.svg' },
  { id: 2, img: '/bank-logos/small-logos/TinkoffBankSmall.svg' },
  { id: 3, img: '/bank-logos/small-logos/LokoBankSmall.svg' },
  { id: 4, img: '/bank-logos/small-logos/PromSvyazBankSmall.svg' },
  { id: 5, img: '/bank-logos/small-logos/RosBankSmall.svg' },
];

const MAX_VAL = 10000000000

export { banks, part1, options, defaultOption, defaultOption1, banksList, MAX_VAL }
