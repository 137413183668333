import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from "react-router-dom"
import './index.css'
import App from './components/App/App'
import reportWebVitals from './reportWebVitals'
import { PrivacyOffice } from "./components/PrivacyOffice"
import {VerificationPage} from "./components/PrivacyOffice/PrivacyAuthPage/VerificationPage";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        {/*<Route path="/privacy_office">*/}
        {/*  <PrivacyOffice />*/}
        {/*</Route>*/}
        {/*<Route path="/verification">*/}
        {/*  <VerificationPage />*/}
        {/*</Route>*/}
        <Route path="/">
          <App />
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals()
