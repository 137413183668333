import './pricesmenu.scss';

function PricesMenu(): JSX.Element {
  return (
    <div className="prices-menu">
      <div className="pr-name">Наименование услуги</div>
      <div className="pr-service">Описание услуги</div>
      <div className="pr-price">Стоимость</div>
    </div>
  )
}

export default PricesMenu;
