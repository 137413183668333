import React, {useContext, useCallback, useState} from 'react'
import { default as NumberFormat} from "react-number-format"
import { observer } from "mobx-react-lite"
import { ru } from 'date-fns/locale'
import Dropdown, { Option } from 'react-dropdown'
import {addDays} from "date-fns"
import { DateRange } from 'react-date-range'
import Popup from 'reactjs-popup'

import { StoreContext } from "../../StoreProvider"
import Banks from '../Banks/Banks'
import Path from '../Path/Path'
import { BanksList } from "./BanksList/BankList"
import { part1, options, defaultOption1, defaultOption, MAX_VAL } from "./constants"
import { DEFAULT_RANGE } from "../constants"

import './finservices.scss'
import '../../styles/tables.scss'
import './tablebankguarantees.scss'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import '../MainLayout/main-layout.scss'
import 'react-dropdown/style.css'
import 'reactjs-popup/dist/index.css'
import 'react-phone-number-input/style.css'

const FinServices = observer(({ setPlaceParam }: any) => {
  const store = useContext(StoreContext)
  const [isBadgeOpened, setBadgeOpened] = useState([]);
  const [period, setPeriod] = useState([{
    startDate: new Date(),
    endDate: addDays(new Date(), 0.1),
    key: 'selection'
  }])

  const withValueLimit = (values: any) => {
    const { formattedValue, floatValue } = values
    return formattedValue === "" || floatValue <= MAX_VAL
  }

  const handleSelectGuarantee = useCallback((option: Option) => {
    store.banks.setBanksField('guarantee', option.value);
    store.banks.setBanksField('errGuarantee','')
    store.banks.getCommissionType()
  }, [store.banks])

  const setOpen = useCallback((key: string) => {
    let arr: any = [...isBadgeOpened]
    let includedId: number = isBadgeOpened.findIndex((item: string) => {
      return item === key
    })
    if (includedId === -1) {
      arr.push(key)
    } else { setBadgeOpened([]); return }
    setBadgeOpened(arr)
  }, [isBadgeOpened, setBadgeOpened])

  const handleChangeTotal = useCallback((e: any) => {
    store.banks.setBanksField('total', e.target.value)
    store.banks.setBanksField('errTotal','')
  }, [store.banks])
  
  const handleCloseCalendar = useCallback((close: any) => {
    store.banks.getPeriodRange(period[0].startDate, period[0].endDate)
    close()
  }, [period, store.banks])

  const handleCheckTotal = useCallback(() => {
    if (store.banks.total === '0') store.banks.setBanksField('errTotal', 'Сумма не может равняться нулю')
  }, [store.banks])

  const handleClearErrPeriod = useCallback(() => store.banks.setBanksField('errPeriod', ''), [store.banks])

  const handleChangePeriod = (item: any) => {
    if (item.selection.startDate >= addDays(new Date(), -1)) setPeriod([item.selection])
  }

  return (
    <section className="finservices">
      <div className="container">
        <Path placeName={'Финансовые услуги'} setPlaceParam={setPlaceParam} />
        <div className="info-header">
          <h2>Финансовые услуги</h2>
        </div>
        <div className="info-block">
          <div className="info-pic">
            <img src="/guarantee.png" alt="банковская гарантия" />
          </div>
          <div className="info-text">
            <p>
              На сегодняшний день, получение банковской гарантии является быстрой, простой и прозрачной процедурой без необходимости открытия расчётного счёта в банке, выдающем гарантию.
            </p>
            <p>
              Выпуск банковской гарантии может составить всего один рабочий день
            </p>
            <p>
              Оплата комиссии осуществляется напрямую в банк.
            </p>
          </div>
        </div>
        <div className="benefits-container">
          <div className="calc-header">
            <p>Преимущества работы</p>
            <p>с нами</p>
          </div>
          <ul className="trade-list">
            <li>
              Вам не придётся готовить множество различных пакетов документов и подстраиваться под требования каждого банка. Мы сами проверим документы, заполним все необходимые формы и отправим их сразу во множество банков, чтобы получить самые выгодные условия.
            </li>
            <li>
              Наши специалисты самостоятельно проверят текст банковской гарантии на соответствие требованиям законодательства и документации о закупке и согласуют с банком все необходимые правки.
            </li>
          </ul>
        </div>
        <div className="bank-services">
          <div className="prices-menu">
            <div className="pr-name fin-name">Наименование услуги</div>
            <div className="pr-service fin-service">Описание услуги</div>
            <div className="pr-price fin-price">Стоимость</div>
          </div>
          {part1.map(item => (
            <div key={item.id} className="ts-block">
              <div className="ts-name">{item.name}</div>
              <div className="mobile">
                <div className="mobile-price">{item.price}</div>
                <div onClick={() => setOpen(item.id)} className="mobile-price">
                  <svg width="12" height="6"
                       transform={isBadgeOpened.find((o: string) => { return o === item.id }) ? "rotate(180)" : "rotate(0)"}
                       viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="0.961628" height="8.02536" transform="matrix(0.750663 -0.660685 0.743744 0.668464 0 0.635254)" fill="#C2C2C2" />
                    <rect width="0.960772" height="8.03518" transform="matrix(0.743744 0.668464 -0.750663 0.660685 11.2842 0.0490723)" fill="#C2C2C2" />
                  </svg>
                </div>
              </div>
              <div className={isBadgeOpened.find((o: string) => { return o === item.id }) ? "ts-service-active" : "ts-service"}>{item.text}</div>
              <div className="ts-price">{item.price}</div>
            </div>)
          )}
        </div>
        <div className="footnote">*Точную стоимость услуги вы можете узнать в день обращения, предоставив подробности нашему менеджеру.</div>
        <div className="bank-guarantee-calculator">
          <div className="calc-header">
            <p>Рассчитать стоимость</p>
            <p>банковской гарантии</p>
          </div>
          <div className="BG-container">
            <p className="calc-about">Калькулятор для предварительного расчёта стоимости банковской гарантии может посчитать возможные предложения от наших банков партнёров</p>
            <div className="bg-dropdown">
              <Dropdown
                options={options}
                onChange={(option) => {handleSelectGuarantee(option)}}
                value={window.document.documentElement.clientWidth > 769 ? defaultOption : defaultOption1}
                controlClassName="bg-control"
                menuClassName="bg-menu"
              />
              <div className="summ-err">{store.banks.errGuarantee}</div>
            </div>
            <div className="guarantee-calculator">
              <div className="calc-wrapper">
                <div className="sum-container">
                  <div className="input-container">
                    <div className="gc-summ-section">
                      <span className="summ-header">Сумма</span>
                      <div>
                        <NumberFormat
                          onChange={handleChangeTotal}
                          thousandSeparator={" "}
                          allowNegative={false}
                          isAllowed={withValueLimit}
                          value={store.banks.total}
                          onBlur={handleCheckTotal}
                          className={store.banks.errTotal ? "err-summ-input" : "summ-input"}
                        />
                        <div className="summ-err">{store.banks.errTotal}</div>
                      </div>
                    </div>
                    <div className="period-section">
                      <span className="period-header">Период</span>
                      <div>
                        <div className={store.banks.errPeriod ? 'err-period-input' : 'period-input'}>
                          {Array.from(period).map((item: any) => (
                            <div className="period-dates" key={item.key}>
                              {store.banks.periodRange !== DEFAULT_RANGE && (
                                <>
                                  <div className="period-start">{item.startDate.toLocaleDateString()}</div>
                                  <span>-</span>
                                  <div className="period-end">{item.endDate.toLocaleDateString()}</div>
                                </>
                              )}
                            </div>
                          ))}
                          <div className="open-calendar">
                            <Popup
                              trigger={
                                <button className="open-calendar">
                                  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.7083 32.0833H7.29167C5.68084 32.0833 4.375 30.7775 4.375 29.1666V8.74996C4.375 7.13913 5.68084 5.83329 7.29167 5.83329H10.2083V2.91663H13.125V5.83329H21.875V2.91663H24.7917V5.83329H27.7083C29.3192 5.83329 30.625 7.13913 30.625 8.74996V29.1666C30.625 30.7775 29.3192 32.0833 27.7083 32.0833ZM7.29167 14.5833V29.1666H27.7083V14.5833H7.29167ZM7.29167 8.74996V11.6666H27.7083V8.74996H7.29167ZM24.7917 26.25H21.875V23.3333H24.7917V26.25ZM18.9583 26.25H16.0417V23.3333H18.9583V26.25ZM13.125 26.25H10.2083V23.3333H13.125V26.25ZM24.7917 20.4166H21.875V17.5H24.7917V20.4166ZM18.9583 20.4166H16.0417V17.5H18.9583V20.4166ZM13.125 20.4166H10.2083V17.5H13.125V20.4166Z" fill="#7A932C" />
                                  </svg>
                                </button>
                              }
                              on="click"
                              onOpen={handleClearErrPeriod}
                              closeOnDocumentClick
                              mouseLeaveDelay={300}
                              mouseEnterDelay={0}
                              arrow={false}
                            >
                              {(close: any) => (
                                <div className="period-fin">
                                  <div className="date-range-wrapper">
                                    <DateRange
                                      locale={ru}
                                      ranges={period}
                                      onChange={handleChangePeriod}
                                      monthDisplayFormat={'LLLL'}
                                      weekdayDisplayFormat={'EEEEEE'}
                                      showSelectionPreview={true}
                                      moveRangeOnFirstSelection={false}
                                      months={
                                        window.document.documentElement.clientWidth > 481 ?
                                          2 : 1 }
                                      direction={"horizontal"}
                                    />
                                  </div>
                                  <div className="period-fin-actions">
                                    <button
                                      className="mod-fin-button"
                                      onClick={() => handleCloseCalendar(close)}
                                    >
                                      Применить
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>
                        </div>
                        <div className="summ-err">{store.banks.errPeriod}</div>
                      </div>
                    </div>
                  </div>
                  <button className="calc-button" onClick={store.banks.countBankGuarantee}>
                    {!store.banks.isOpened ? 'Рассчитать' : 'Пересчитать'}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {store.banks.isOpened && <BanksList period={period} />}
        </div>
        <Banks />
        <div className="company-policy">
          <div>
            ООО «СтройТендерКонсалтинг» не оказывает финансовые услуги и не предоставляет финансовые продукты лично. Все финансовые услуги (продукты) предоставляют компании и банки партнёры с полным сопровождением ООО «СтройТендерКонсалтинг» на основании договорных отношений.
          </div>
        </div>
      </div>
    </section>
  )
})

export default FinServices;
