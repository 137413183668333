import React, {useContext} from "react"
import { StoreContext } from "../../../StoreProvider"
import {BankItem} from "./BankItem/BankItem"
import { observer } from "mobx-react-lite"
import './styles.scss'

export const BanksList = observer((props: any) => {
  const { period } = props
  const store = useContext(StoreContext)

  return (
    <div className="table-container">
      {!store.banks.theFirstBank && !store.banks.theSecondBank && !store.banks.theThirdBank && !store.banks.theForthBank && !store.banks.theFifthBank ? (
        <div className="table-nothing-badge">К сожалению, по вашему запросу не найдено предложений</div>
      ) : (
        <>
          <div className="table-title">
            <div className="table-title-cell-bank">
              <span className="title-span">
                Банк
              </span>
            </div>
            <div className="table-title-cell params">
              <span className="title-span title-button cost">
                Стоимость
              </span>
            </div>
            <div className="table-title-cell params">
              <span className="title-span title-button">
                Ставка
              </span>
            </div>
            <div className="empty">
            </div>
          </div>
          {store.banks.theFirstBank && <BankItem bank={store.banks.theFirstBank} commissionPrice={store.banks.theFirstCommission} period={period} />}
          {store.banks.theSecondBank && <BankItem bank={store.banks.theSecondBank} commissionPrice={store.banks.theSecondCommission} period={period} />}
          {store.banks.theThirdBank && <BankItem bank={store.banks.theThirdBank} commissionPrice={store.banks.theThirdCommission} period={period} />}
          {store.banks.theForthBank && <BankItem bank={store.banks.theForthBank} commissionPrice={store.banks.theForthCommission} period={period} />}
          {store.banks.theFifthBank && <BankItem bank={store.banks.theFifthBank} commissionPrice={store.banks.theFifthCommission} period={period} />}
        </>
      )}
    </div>
  )
})
