import React, {useCallback} from "react"
import { useHistory } from 'react-router-dom'
import { Button } from "../Button"
import "./pageNotFound.scss"

export const PageNotFound = () => {
  const history = useHistory()

  const handleClickMainPage = useCallback((e:Event) => {
    e.preventDefault()
    history.push('/');
  }, [history])

  const handleClickBack = useCallback((e:Event) => {
    e.preventDefault()
    history.goBack()
  }, [history])

  return (
    <div className="not-found__wrapper">
      <div className="not-found__container container">
        <div className="not-found__title">Ошибка</div>
        <div className="not-found__code">404</div>
        <div className="not-found__subtitle">Запрашиваемая страница не найдена</div>
        <div className="not-found__text">Возможно вы ошиблись в адресе или страница была перемещена.</div>
        <div className="not-found__text">Вы можете вернуться назад или перейти на главную страницу.</div>
        <div className="not-found__button-container">
          <Button text="Назад" onClick={handleClickBack} />
          <Button text="На главную" onClick={handleClickMainPage} />
        </div>
      </div>
    </div>
  )
}
