import React, {useCallback} from "react";
import PhoneInput from "react-phone-number-input";
import {Link} from "react-router-dom";
import { checkPhoneLength } from "../../utils";
import './CreateRequest.scss'
import { getFullDate } from "../../utils";
import { useCreateForm } from "../../hooks";

export const CreateRequestForm = (props: any) => {
  const {
    title,
    services,
    close,
    bank,
    guarantee,
    period,
    total,
    summ,
    commissionPrice,
  } = props
  const {
    makeAnOrder,
    onHandleSetClientName,
    onHandleSetPolicy,
    onHandleSetClientPhone,
    isSent,
    clientName,
    clientPhone,
    isPolicy,
    errName,
    errPhone,
    errPolicy,
    setErrPhone,
  }: any = useCreateForm()

  const onHandleGetFullDate = useCallback(getFullDate, [period])

  const onHandleSubmit = (e: any) => {
    e.preventDefault()
    makeAnOrder(e.target, close)
  }

  const handleCheckPhoneLength = useCallback(() => checkPhoneLength(clientPhone, setErrPhone), [clientPhone, setErrPhone])

  return (
    isSent ? (
      <div className="notification-wrapper">
        <div className="notification-container">
          <div className="send-icon" />
          <div className="notification-title">Заявка отправлена</div>
          <div className="notification-text">Наш менеджер свяжется с вами в ближайшее время</div>
        </div>
      </div>
    ) : (
      <div className="mod-fin">
        <div className="mod-fin-img-container">
          <img src="/popecp.png" alt="банковская гарантия" />
        </div>
        <form onSubmit={onHandleSubmit}>
          <div className="mod-fin-header">{title}</div>
          <div className="mod-fin-text">
            Заполните форму, наш менеджер свяжется с вами в ближайшее время
          </div>
          <input
            className={errName ? "err" : "usual"}
            onChange={e => onHandleSetClientName(e)}
            value={clientName}
            type="text"
            name="clientName"
            placeholder="Имя"
          />
          {errName && <div className="err-notification">{errName}</div>}
          <PhoneInput
            name="clientPhone"
            international
            className={errPhone && "ph-err"}
            defaultCountry="RU"
            limitMaxLength={true}
            value={clientPhone}
            onChange={onHandleSetClientPhone}
            onBlur={handleCheckPhoneLength}
          />
          {errPhone && <div className="err-notification">{errPhone}</div>}
          <input defaultValue={title} name="title" hidden />
          {total && <input defaultValue={total} name="total" hidden />}
          {summ && <input defaultValue={summ} name="summ" hidden />}
          {bank && <input defaultValue={bank.minBet} name="bet" hidden />}
          {services && <input defaultValue={services} name="services" hidden />}
          {guarantee && <input defaultValue={guarantee} name="guarantee" hidden/>}
          {commissionPrice && <input defaultValue={commissionPrice} name="commissionPrice" hidden/>}
          {bank && <input defaultValue={bank.name} name="bank" hidden />}
          {period && <input defaultValue={onHandleGetFullDate(period[0].startDate)} name="start" hidden />}
          {period && <input defaultValue={onHandleGetFullDate(period[0].endDate)} name="end" hidden />}
          <div className={errPolicy ? "mod-fin-policy-err" : "mod-fin-policy"}>
            <input
              onChange={e => onHandleSetPolicy(e)}
              checked={isPolicy}
              type="checkbox"
              name="policy"
            />
            <label htmlFor="policy">
              <div>Я согласен с условиями</div>
              <Link to="/policy">
                <div className="policy">политики конфиденциальности</div>
              </Link>
            </label>
          </div>
          {errPolicy && <div className="err-policy">{errPolicy}</div>}
          <div className="mod-fin-actions">
            <button
              className="mod-fin-button"
              type="submit"
            >
              Отправить
            </button>
          </div>
        </form>
      </div>
    )
  )
}
