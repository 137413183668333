import '../MainLayout/main-layout.scss'
import Path from "../Path/Path";
import React from "react";
import './lk.scss'

const Lk = ({ setPlaceParam }: any): JSX.Element => (
  <div className="private-section">
    <div className="container">
      <Path placeName={'Личный кабинет'} setPlaceParam={setPlaceParam} />
      <div className="private-container">
        <div className="private-title">Уважаемые клиенты!</div>
        <div className="private-subtitle">В настоящий момент личный кабинет находится в разработке.</div>
        <div className="private-list-title">В скором времени вам будет доступен следующий функционал</div>
        <ul className="private-list">
          <li>Подписание с нами договора и актов оказанных услуг в электронной форме. Это избавит от обмена бумажными документами, что будет особенно полезно для клиентов из других городов.</li>
          <li>Зачисление денежных средств на виртуальный лицевой счёт. Это позволит вам с лёгкостью отслеживать все оплаты в наш адрес. Также вы сможете вернуть свободные денежные средства себе на расчётный счёт в автоматическом режиме</li>
          <li>Удобное и понятное согласование стоимости услуг, имеющих нефиксированную цену. Сейчас существует некоторое неудобство при согласовании стоимости тех услуг, которые мы можем оценить только после того, как получим вашу заявку</li>
        </ul>
      </div>
    </div>
  </div>
)

export default Lk
