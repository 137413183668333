type IRating = {
  id: number,
  name: string,
  img: string,
  rating: number,
  maxPeriod: number,
  maxCount: number,
  minBet: number,
}

const filterByRating = (array: IRating[], minRating: number, maxRating: number) => {
  return array.filter((bank: IRating) => bank.rating <= maxRating && bank.rating > minRating)
}

const filterByMaxPeriod = (array: IRating[], period: number) => {
  return array.filter((bank: IRating) => bank.maxPeriod >= period)
}

const filterByMaxCount = (array: IRating[], count: number) => {
  return array.filter((bank: IRating) =>  bank.maxCount >= count)
}

const filterByMinBet = (array: IRating[]) => {
  return array.sort((bank: IRating) => bank.minBet)
}

export { filterByMaxCount, filterByMaxPeriod, filterByMinBet, filterByRating }
