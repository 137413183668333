import './banks.scss';

function Banks(): JSX.Element {
  return (
    <section className="banks">
      <div className="banks-header">
        <p>Банки</p>
        <p>партнеры</p>
      </div>
      <div className="banks-info">
        <div className="banks-navigate">
          <p>
            Мы являемся партнёрами более 50 банков, которые вправе выдавать банковские гарантии для обеспечения обязательств при участии в закупках по 44-ФЗ, 223-ФЗ и 615 ПП РФ (капитальный ремонт многоквартирных домов)
          </p>
        </div>
        <div className="banks-slider">
          <div className="bank-item">
            <img src="/bank-logos/Sber.png" alt="bank_logo" />
          </div>
          <div className="bank-item">
            <img src="/bank-logos/PromSvyazBank.png" alt="bank_logo" />
          </div>
          <div className="bank-item">
            <img src="/bank-logos/BankRossiya.png" alt="bank_logo" />
          </div>
          <div className="bank-item">
            <img src="/bank-logos/TinkoffBank.png" alt="bank_logo" />
          </div>
          <div className="bank-item">
            <img src="/bank-logos/RosBank.png" alt="bank_logo" />
          </div>
          <div className="bank-item">
            <img src="/bank-logos/BankOtkrytie.png" alt="bank_logo" />
          </div>
          <div className="bank-item">
            <img src="/bank-logos/LokoBank.svg" alt="bank_logo" />
          </div>
          <div className="bank-item">
            <img src="/bank-logos/AbsoluteBank.svg" alt="bank_logo" />
          </div>
          <div className="bank-item">
            <img src="/bank-logos/BksBank.png" alt="bank_logo" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banks;
