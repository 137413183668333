import React, {useCallback} from 'react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import PhoneInput from 'react-phone-number-input';
import { useCreateForm } from "../../hooks";
import { checkPhoneLength } from "../../utils";
import 'react-phone-number-input/style.css';
import './Contactus.scss';

function ContactUs() {
  const {
    makeAnOrder,
    onHandleSetClientName,
    onHandleSetPolicy,
    onHandleSetClientPhone,
    clientName,
    clientPhone,
    errName,
    errPhone,
    setErrPhone,
    errPolicy,
    isSent,
    isPolicy,
    clientQuestion,
    setQuestion,
  }: any = useCreateForm();

  const handleCheckPhoneLength = useCallback(() => checkPhoneLength(clientPhone, setErrPhone), [clientPhone, setErrPhone])

  return (
    <section className="contact-us">
      <div>
        <h2 className="call-header">Связаться с нами</h2>
        <p className="call-paragraph">
          Вы можете связаться с нами, позвонив по телефону или заполнив форму обратной связи.
          Ваше обращение будет рассмотрено менеджерами компании в ближайшее время.
        </p>
      </div>
      <form onSubmit={(e) => {
        e.preventDefault()
        makeAnOrder(e.target)
      }}>
        <div className="input-group">
          <div className="contact-group">
            <input name="title" hidden />
            <input
              className={errName ? "err" : "usual"}
              onChange={onHandleSetClientName}
              type="text"
              name="clientName"
              value={clientName}
              placeholder="Имя"
            />
            <div className="name-err">{errName}</div>
            <PhoneInput
              name="clientPhone"
              international={true}
              className={errPhone ? "ph-err" : ""}
              limitMaxLength={true}
              defaultCountry="RU"
              value={clientPhone}
              onChange={onHandleSetClientPhone}
              onBlur={handleCheckPhoneLength}
            />
            <div className="phone-err">{errPhone}</div>
          </div>
          <div>
            <textarea
              onChange={e => setQuestion(e.target.value)}
              name="question"
              value={clientQuestion}
              placeholder="Ваш вопрос"
            />
          </div>
        </div>
        <div className="form-zakaz">
          <div className="copo-err">
            <div className="contactus-policy">
              <input
                onChange={onHandleSetPolicy}
                type="checkbox"
                name="policy"
                checked={isPolicy}
              />
              <label htmlFor="policy">
                <div>Я согласен с условиями</div>
                <Link to="/policy">
                  <div className="policy">политики конфиденциальности</div>
                </Link>
              </label>
            </div>
            <div className="policy-err">{errPolicy}</div>
          </div>
          <button type="submit" className="zakaz">Заказать звонок</button>
          <Popup
            open={isSent}
            contentStyle={{ borderRadius: "6px", width: "auto"  }}
            modal
            nested
          >
            <div className="notification-wrapper">
              <div className="notification-container">
                <div className="send-icon" />
                <div className="notification-title">Заявка отправлена</div>
                <div className="notification-text">Наш менеджер свяжется с вами в ближайшее время</div>
              </div>
            </div>
          </Popup>
        </div>
      </form>
    </section>
  )
}

export default ContactUs;
