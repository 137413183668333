function Email(): JSX.Element {
  return (
    <div className="email-component">
      <a href="mailto:info@stkrnd.com" className="icon-phone">
        <img src="emailIcon.svg" alt="info@stkrnd.com" />
      </a>
      <span>info@stkrnd.com</span>
    </div>
  )
}

export default Email;
