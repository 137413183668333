import React, {useEffect, useMemo, useState} from 'react';
import '../MainLayout/main-layout.scss'
import Popup from 'reactjs-popup';
import '../MainLayout/main-layout.scss'
import 'reactjs-popup/dist/index.css';
import 'react-phone-number-input/style.css';
import './esignature.scss';
import Path from '../Path/Path';
import { CreateRequestForm } from "../CreateRequestForm/CreateRequestForm";

interface Obj {
  name: string
  price: number
}

type IECPOptions = {
  name: string;
  price: number;
  label: string;
  url: string
};

const ECPOptions: IECPOptions[] = [
  { name: "b2b-center", price: 2500, label: "ЭТП B2B-Center", url: "https://www.b2b-center.ru/"},
  { name: "nep-fabrikant", price: 2500, label: "ЭТП НЭП-Фабрикант", url: "https://www.fabrikant.ru/"},
  { name: "tek-torg", price: 1500, label: "ЭТП ТЭК-Торг (секция ПАО «НК «Роснефть»)", url: "https://www.tektorg.ru/" },
  { name: "gpb", price: 3500, label: "ЭТП ГПБ", url: "https://etpgpb.ru/" },
  { name: "regtorg", price: 3000, label: "ЭТП Регторг", url: "https://regtorg.com/" },
  { name: "esp", price: 2000, label: "ЭТП ЭСП", url: "http://el-torg.com/" },
  { name: "centrrealizacii", price: 1500, label: "ЭТП Центр Реализации", url: "https://centerr.ru/" },
  { name: "cdt", price: 4500, label: "ЭТП ЦДТ «Участник торгов (покупатель)»", url: "https://cdtrf.ru/" },
  { name: "aist", price: 2000, label: "ЭТП АИСТ, uTender, Объединённая торговая площадка", url: "http://www.aistorg.ru/ http://www.utender.ru/ https://bankrupt.utpl.ru/" },
];

const ESignature = ({ setPlaceParam }: any) => {
  const [ECPOptionTool, setECPOptionTool] = useState([]);
  const [ECPType, setECPType] = useState<string>('Электронная подпись для госсистем');
  const [summ, setSumm] = useState<number>(0);

  const serviceNames = useMemo(() => {
    return `${ECPOptionTool.map((service: Obj) => service.name)}, ${ECPType}`
  }, [ECPOptionTool, ECPType])

  //выбор товаров и подсчёт итоговой суммы
  const chooseECPOptionTool = (target: any) => {
    let arr: any = [...ECPOptionTool];
    let includedItem: number = ECPOptionTool.findIndex((item: Obj) => {
      return item.name === target.name;
    });

    if (includedItem === -1) {
      let obj = {
        'name': target.name,
        'price': +target.value
      };
      arr.push(obj);
      setECPOptionTool(arr);
    } else {
      if (arr.length === 1) {
        arr = [];
      } else {
        let firstSubArr = ECPOptionTool.slice(includedItem + 1);
        let secondSubArr = ECPOptionTool.slice(0, includedItem);
        arr = [...firstSubArr.concat(secondSubArr)];
      }
      setECPOptionTool(arr);
    }
  }

  //переключение цен при смене типа ЭП
  const setPrice = (value: number) : number => {
    if (ECPType === 'Электронная подпись для госсистем') {
      return value
    } else {
      if (value === 1500) return value;
      return value - 500;
    }
  }

  //подсчёт итоговой суммы
  useEffect(() => {
    let summary = ECPOptionTool.reduce((accumulator: number, currentItem: any) => {
      return accumulator + +currentItem.price;
    }, 0);
    let ecpPrice = ECPType === 'Электронная подпись для госсистем' ? 1500 : 3000;
    setSumm(summary + ecpPrice);
  }, [ECPOptionTool, ECPType]);

  //кнопка "заказать ЭП"
  const zakazECP = (e: any) => {
    e.preventDefault();
  }

  //смена названия одной из ЭП в зависимости от ширины экрана
  const opg = (label: string) => {
    if (label === "ЭТП Объединённая торговая площадка"
      && window.document.documentElement.clientWidth < 768) {
    return "ОТП" } else return label;
  }

  return (
    <section className="esignature">
      <div className="container">
        <Path placeName={'Электронная подпись'} setPlaceParam={setPlaceParam} />
        <div className="info-header">
          <h2>Электронная подпись</h2>
        </div>
        <div className="info-block">
          <div className="info-pic">
            <img src="/ecp.png" alt="электронная подпись" />
          </div>
          <div className="info-text">
            <p>
              Наша компания является официальным партнером аккредитованного удостоверяющего центра и готова предложить усиленные квалифицированные электронные подписи для участия в торгах.
            </p>
            <p>
              Выпуск электронной подписи обычно занимает 1 рабочий день, но в случае острой необходимости, подпись может быть выпущена за 2 часа, при условии предоставления всех необходимых документов, оплаты и обязательного визита в ближайшую точку подтверждения личности владельца сертификата.
            </p>
          </div>
        </div>
        <div className="prices">
          <div className="prices-header">
            <p>Рассчитать</p>
            <p>стоимость</p>
          </div>
          <div className="choose-esign">
            <p className="esign-header">Выберите тип электронной подписи</p>
            <div className="esign-type">
              <div className="esign-item">
                <div className="esign-box">
                  <input
                    onChange={(e: any) => setECPType(e.target.name)}
                    type="checkbox"
                    name="Электронная подпись для госсистем"
                    value="1500"
                    checked={ECPType === 'Электронная подпись для госсистем'}
                  />
                  <label className="ecp-type" htmlFor="Электронная подпись для госсистем">Электронная подпись для госсистем</label>
                  <div className="esign-price">1500 рублей</div>
                </div>
                <div className="esign-about">
                  Подходит для использования на портале Госуслуг, в личном кабинете ФНС,
                  электронного документооборота, и многих других сервисов, где в составе электронной
                  подписи не требуется особый объектный идентификатор (OID). Срок действия 12 месяцев
                </div>
              </div>
              <div className="esign-item">
                <div className="esign-box">
                  <input
                    onChange={(e: any) => setECPType(e.target.name)}
                    type="checkbox"
                    name="Электронная подпись для госзакупок"
                    value="3000"
                    checked={ECPType === 'Электронная подпись для госзакупок'}
                  />
                  <label className="ecp-type" htmlFor="Электронная подпись для госзакупок">Электронная подпись для госзакупок</label>
                  <div className="esign-price">3000 рублей</div>
                </div>
                <div className="esign-about">
                  Включает в себя применение “Электронная подпись для госсистем”, а также подходит
                  для участия в электронных торгах по 44-ФЗ и 223-ФЗ, за исключением площадок,
                  которые требуют в составе электронной подписи особый объектный идентификатор (OID).
                  Срок действия 12 месяцев
                </div>
              </div>
            </div>
          </div>
          <div className="esign-options">
            <p className="esign-header">Выберите дополнительные электронные торговые площадки</p>
            {
              ECPOptions.map(option =>
                (
                  <div key={option.name} className="esign-box">
                    <input
                      type="checkbox"
                      name={option.label}
                      value={setPrice(option.price)}
                      onChange={(e) => chooseECPOptionTool(e.target)}
                    />
                    <div className="ecp-options">
                      <label htmlFor={option.name}>
                        <p>{opg(option.label)}</p>
                      </label>
                      <Popup
                        contentStyle={{width: "auto", display: 'flex' }}
                        trigger={
                          <div className="notification-button">
                            <span className="notification-span" />
                          </div>
                        }
                        position="bottom center"
                        on="hover"
                        closeOnDocumentClick
                        mouseLeaveDelay={300}
                        mouseEnterDelay={0}
                        arrow={false}
                      >
                        {option.name !== 'aist' ? (
                          <div className="esign-info">
                            <a href={option.url} rel="noreferrer">{option.url}</a>
                          </div>
                        ) : (
                          <div className="esign-info">
                            <a href="http://www.aistorg.ru/" rel="noreferrer">http://www.aistorg.ru/ </a>
                            <div className="break" />
                            <a href="http://www.utender.ru/" rel="noreferrer">http://www.utender.ru/ </a>
                            <div className="break" />
                            <a href="https://bankrupt.utpl.ru/" rel="noreferrer">https://bankrupt.utpl.ru/</a>
                          </div>
                        )}
                      </Popup>
                    </div>
                    <div className="esign-price">{setPrice(option.price)} рублей</div>
                  </div>
                ))}
          </div>
          <div className="esign-tools">
            <p className="esign-header">Дополнительно для работы с электронной подписью вам может понадобится</p>
            <div className="esign-box">
              <input
                type="checkbox"
                name="Встроенная в электронную подпись лицензия на право использования СКЗИ КриптоПро CSP"
                value="1000"
                onChange={(e) => {chooseECPOptionTool(e.target)}}
              />
              <label className="ecp-tools" htmlFor="Встроенная в электронную подпись лицензия на право использования СКЗИ КриптоПро CSP">Встроенная в электронную подпись лицензия на право использования СКЗИ "КриптоПро CSP"</label>
              <div className="esign-price">1000 рублей</div>
            </div>
            <div className="esign-about">
              Программное обеспечение КриптоПро CSP требуется для работы электронной подписи на компьютере. Срок действия лицензии равен сроку действия электронной подписи.
            </div>
            <div className="esign-box">
              <input
                type="checkbox"
                name="Защищенный носитель Рутокен Lite"
                value="1000"
                onChange={(e) => {chooseECPOptionTool(e.target)}}
              />
              <label className="ecp-tools" htmlFor="Защищенный носитель Рутокен Lite">Защищенный носитель Рутокен Lite</label>
              <div className="esign-price">1000 рублей</div>
            </div>
            <div className="esign-about">
              Носитель Рутокен Lite можно использовать для безопасного и надёжного хранения электронной подписи.
            </div>
            <div className="esign-box">
              <input
                type="checkbox"
                name="Настройка компьютера для работы на ЭТП"
                value="1000"
                onChange={(e) => {chooseECPOptionTool(e.target)}}
              />
              <label className="ecp-tools" htmlFor="Настройка компьютера для работы на ЭТП">Настройка компьютера для работы на ЭТП</label>
              <div className="esign-price">1000 рублей</div>
            </div>
            <div className="esign-about">
              Настройка производится в нашем офисе или с помощью программы удалённого доступа.
            </div>
          </div>
          <div className="esign-summary">
            <div className="esign-numbers">
              <div>Итого: </div>
              <div className="esign-summ">{summ}</div>
            </div>
            <Popup
              contentStyle={{ borderRadius: "6px", width: "auto"  }}
              trigger={
                <button
                  className="esign-button"
                  onClick={(e) => zakazECP(e)}
                  disabled={!summ}
                >Заказать</button>
              }
              modal
              nested
            >
              {(close: any) => (
                <CreateRequestForm close={close} summ={summ} title="Заявка на электронную подпись" services={serviceNames} />
              )}
            </Popup>
          </div>
          <div className="company-policy">
            ООО «СтройТендерКонсалтинг» не оказывает услуги по выпуску электронной подписи лично. Услуга предоставляется удостоверяющим центром IT-COM с полным сопровождением ООО «СтройТендерКонсалтинг» на основании договорных отношений.
          </div>
        </div>
      </div>
    </section>
  )
}

export default ESignature;
