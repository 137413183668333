export const setOpen = ( key: string, opened: any, setOpened: any) => {
  let arr: any = [...opened];
  let includedId: number = opened.findIndex((item: string) => {
    return item === key;
  });

  if (includedId === -1) {
    arr.push(key);
  } else { setOpened([]); return; }
  setOpened(arr);
}
