import {isPossiblePhoneNumber} from "react-phone-number-input";

const checkInput = (field: string) => {
  if (field === '') {
    return 'Строка обязательна для заполнения'
  } else if (field.trim() === '') {
    return 'Строка состоит только из пробелов'
  }
}

const checkCommonInput = (field: string, error: string, action: any) => {
  if (field === '') {
    action(error, 'Строка обязательна для заполнения')
  } else if (field.trim() === '') {
    action(error, 'Строка состоит только из пробелов')
  }
}

const checkInputWithFormat = (field: string, regExp: any, error: string, action: any) => {
  if (field === '') {
    action(error, 'Строка обязательна для заполнения')
  } else if (!regExp.test(field)) {
    action(error, 'Неверный формат Email')
  }
}

const checkPasswordInput = (field: string, error: string, action: any) => {
  if (field === '') {
    action(error, 'Строка обязательна для заполнения')
  } else if (field.length < 6) {
    action(error, 'Пароль слишком короткий')
  }
}

const checkPhoneInput = (field: string, error: string, action: any) => {
  if (field === '') {
    action(error, 'Строка обязательна для заполнения')
  } else if (!isPossiblePhoneNumber(field)) {
    action(error, 'Неверный формат номера')
  }
}

export { checkCommonInput, checkInputWithFormat, checkPasswordInput, checkPhoneInput, checkInput }
