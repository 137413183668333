import React, {useCallback, useState} from "react";
import { checkError, checkString } from "../utils";
import emailjs from "emailjs-com";
import {SERVER_ID, TEMPLATE_ID, USER_ID} from "../components/CreateRequestForm/constants";

const clearError = (setErr: any): void => setErr('')

export const useCreateForm = () => {
  const [isSent, setSent] = useState<boolean>(false)
  const [clientName, setClientName] = React.useState<string>('');
  const [clientPhone, setPhone] = React.useState<string>('');
  const [isPolicy, setPolicy] = React.useState<boolean>(false);
  const [errName, setErrName] = React.useState<string>('');
  const [errPhone, setErrPhone] = React.useState<string>('');
  const [errPolicy, setErrPolicy] = React.useState<string>('');
  const [clientQuestion, setQuestion] = useState<string>('');

  const onHandleSetClientName = useCallback((e: any) => {
    setClientName(e.target.value)
    clearError(setErrName)
  }, [errName, clientName])

  const onHandleSetClientPhone = useCallback((value: string) => {
    setPhone(value)
    clearError(setErrPhone)
  }, [errPhone, clientPhone])

  const onHandleSetPolicy = useCallback((e: any) => {
    setPolicy(e.target.checked)
    setErrPolicy('')
  }, [errPolicy, isPolicy])

  const makeAnOrder = (target: any, close: any) => {
    checkString(clientName, setErrName)
    checkError(clientPhone, setErrPhone, 'Поле обязательно для заполнения')
    checkError(isPolicy, setErrPolicy, 'Необходимо ваше согласие')

    if (isPolicy && clientPhone && clientName && !errPhone) {
      emailjs.sendForm(
        SERVER_ID,
        TEMPLATE_ID,
        target,
        USER_ID
      )
        .then(() => {
          setSent(true);
          setTimeout(() => {
            setSent(false);
            setClientName('');
            setPhone('');
            setPolicy(false);
            if (clientQuestion) {
              setQuestion('')
            }
            if (close) {
              close();
            }
          }, 3000)
        })
        .catch((error) => console.log(error.text));
    }
  }

  return {
    makeAnOrder,
    onHandleSetClientName,
    onHandleSetPolicy,
    onHandleSetClientPhone,
    isSent,
    clientName,
    clientPhone,
    isPolicy,
    errName,
    errPhone,
    setErrPhone,
    errPolicy,
    clientQuestion,
    setQuestion,
  }
}
