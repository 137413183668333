import { useEffect, useState } from 'react'
import StoreProvider from "../../StoreProvider"
import { RouteWrapper } from "./RouteWrapper"
import {useLocation, withRouter, Switch} from 'react-router-dom'
import Header from '../Header/Header'
import DesktopMenu from '../DesktopMenu/DesktopMenu'
import Footer from '../Footer/Footer'
import MainPage from '../MainPage/MainPage'
import ESignature from '../ESignature/ESignature'
import Monitoring from '../Monitoring/Monitoring'
import About from '../About/About'
import FinServices from '../FinServices/FinServices'
import Trade from '../Trade/Trade'
import Policy from "../Policy/Policy"
import Lk from '../Lk/Lk'
import { PageNotFound } from "../PageNotFound"

import '../../styles/policy.scss'
import '../../styles/pricelist.scss'
import './App.scss'

const _ScrollToTop = (props: any) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return props.children
}

const ScrollToTop = withRouter(_ScrollToTop)

const App = ({ location }: any) => {
  const [placeParam, setPlaceParam] = useState('')
  useEffect(() => {
    document.title = 'ООО "СтройТендерКонсалтинг"'
  })

  return (
    <StoreProvider>
      <div className="App">
        <Header setPlaceParam={setPlaceParam} />
        <main className="main">
          <DesktopMenu setPlaceParam={setPlaceParam} placeParam={placeParam} />
          <ScrollToTop>
            <Switch>
              <RouteWrapper path="/" exact component={MainPage} />
              <RouteWrapper path="/esignature" exact location={location}>
                <ESignature setPlaceParam={setPlaceParam} />
              </RouteWrapper>
              <RouteWrapper path="/finservices" exact location={location}>
                <FinServices setPlaceParam={setPlaceParam} />
              </RouteWrapper>
              <RouteWrapper path="/monitoring" exact location={location}>
                <Monitoring setPlaceParam={setPlaceParam} />
              </RouteWrapper>
              <RouteWrapper path="/trade" exact location={location}>
                <Trade setPlaceParam={setPlaceParam} />
              </RouteWrapper>
              <RouteWrapper path="/about" exact location={location}>
                <About setPlaceParam={setPlaceParam} />
              </RouteWrapper>
              <RouteWrapper path="/policy" exact location={location}>
                <Policy />
              </RouteWrapper>
              <RouteWrapper path="/Lk" exact location={location}>
                <Lk />
              </RouteWrapper>
              <RouteWrapper component={PageNotFound} />
            </Switch>
          </ScrollToTop>
        </main>
        <Footer />
      </div>
    </StoreProvider>
  )
}

export default App
