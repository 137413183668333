import Path from '../Path/Path';
import '../MainLayout/main-layout.scss'

import './about.scss';
import Phone from "../Phone";
import MapContainer from "./MapContainer";
import ContactUs from "../ContactUs/ContactUs";
import Email from "../Email";

function About({ setPlaceParam }: any): JSX.Element {
  return (
    <section className="contacts-page">
      <div className="container">
        <Path placeName={'О компании'} setPlaceParam={setPlaceParam} />
        <div className="contacts-section">
          <div className="about-info">
            <div className="left-part">
              <div className="company-name">
                <div>ООО "СтройТендерКонсалтинг"</div>
              </div>
              <div className="about-text">
                <p>
                  Наша компания оказывает услуги по комплексному сопровождению участия в закупках уже более 10 лет. Этот период наши клиенты успешно поучаствовали более чем в 12 000 закупках.
                </p>
                <p>
                  Обладая комплексными знаниями законодательства и правоприменительной практики контрольных органов, каждый наш сотрудник является универсальным экспертом в сфере публичных торгов
                </p>
              </div>
              <div className="contacts-header">Наши контакты</div>
            </div>
            <div className="right-part">
              <div className="about-image-wrapper">
                <img src="/aboutBackground.png" alt="about"/>
              </div>
            </div>
          </div>
        </div>
        <div className="contacts-section">
          <div className="company-info">
            <div className="number-contacts">
              <Phone />
              <Email />
            </div>
            <div className="address-contacts">
              <p className="contact-item">Россия, г. Ростов-на-Дону</p>
              <p className="contact-item">ул. Текучева 37, офис 300Г</p>
            </div>
            <div className="address-contacts">
              <p className="contact-item">пн-пт с 8:00 до 17:00</p>
              <p className="contact-item">сб-вс выходной</p>
            </div>
          </div>
        </div>
        <MapContainer />
        <ContactUs />
      </div>
    </section>
  )
}

export default About;
